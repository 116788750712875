var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',{attrs:{"title":"Newtons","icon":"mdi-router-network","wide":""}},[_c('EditableTable',{attrs:{"table-subscription":(handler) => this.$api.newton.subscribeAll(handler),"fields":[
        {fieldName:'id', displayName: 'Id', type: String},
        {fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[]},
        {fieldName:'enabled', displayName: 'Enabled', editable: true, type: Boolean, rules:[]},
        {fieldName:'householdId', displayName: 'Household Id', editable: true, type: String, rules:[]},
        {fieldName:'remoteIP', displayName: 'IP', editable: true, type: String, rules:[]},
        {fieldName:'localIP', displayName: 'Local IP', editable: true, type: String, rules:[]},
        {fieldName:'codeHash', displayName: 'Code Hash', type: String, rules:[]},
        {fieldName:'verificationCode', displayName: 'Verification Code', editable: true, type: String, rules:[]},
        {fieldName:'status', displayName: 'Status', type: 'Enumerator', rules:[]},
        {fieldName:'currentHostname', displayName: 'Hostname', type: String, rules:[]},
        {fieldName:'statusTimestamp', displayName: 'Status Timestamp', type: String, rules:[]},
        ],"save-method":(data) => this.$api.newton.saveNewtonAdmin(data)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }