<template>
  <div>
    <div
      v-if="viewFields && objects && objects.length > 0">

      <v-data-table
        style="border-collapse: separate;"
        :items="objects"
        :headers="viewFields"
        :search="filter"
        :show-expand="saveMethod ? true : false"
        single-expand
        :expanded.sync="expanded"
        hide-default-footer
        disable-pagination
        :item-class="getClass"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <DynamicForm
              :key="reloadForm"
              :object="item"
              :fields="editFields"
              :saveMethod="saveMethod"
              :cancelCallback="cancelEditing"
              :saveText="saveText"
              :successCallback="successDoneEditing"
            >
            </DynamicForm>
          </td>
        </template>

        <!-- We SHOULD be able to do this by type but I can't figure it out      -->
        <template v-slot:item.enabled="{ item }">
          <v-simple-checkbox
            v-model="item.enabled"
            disabled
          />
        </template>
        <template v-slot:item.pending="{ item }">
          <div v-if="item.pending">Pending</div>
        </template>
        <template v-slot:item.id="{ item }">
          <div class="shortColumn">{{item.id}}</div>
        </template>
        <template v-slot:item.householdId="{ item }">
          <div class="shortColumn">{{item.householdId}}</div>
        </template>
        <template v-slot:item.hash="{ item }">
          <div class="shortColumn">{{item.hash}}</div>
        </template>
        <template v-slot:item.statusTimestamp="{ item }">
          <!--        {{moment.utc(now)}}-->
          <!--        <br/>-->
          {{moment(item.statusTimestamp).from(moment.utc(now))}}
        </template>
        <template v-slot:item.lastFullSync="{ value }">
          <div v-if="value">
            {{moment(value).from(moment.utc(now))}}
          </div>
        </template>
        <template v-slot:item.createdTimestamp="{ item }">
          {{moment(item.createdTimestamp)}}
        </template>
        <template v-slot:item.balance="{ item }">
          {{formatMoney(item.balance)}}
        </template>
        <template v-slot:item.amount="{ item }">
          {{formatMoney(item.amount)}}
        </template>
        <template v-slot:item.balanceWithPending="{ item }">
          <div v-if="item.balanceWithPending === item.balanceWithoutPending">
            {{formatMoney(item.balanceWithPending)}}
          </div>
          <div v-else>
            {{formatMoney(item.balanceWithoutPending)}}
            ({{formatMoney(item.balanceWithPending)}})
          </div>
          <div v-if="item.type === 'CREDIT' && item.creditLimit">
            {{formatMoney(-1 * item.creditLimit + item.balanceWithPending)}}
          </div>
        </template>
        <template v-slot:item.date="{ value }">
          {{new Date(value).toLocaleString("en-US", {day: "numeric", month: "short", year: "numeric"})}}
        </template>

        <template v-slot:item.type="{ value, header, item }">
          {{getEnumName(header, value)}}
          <span v-if="item.type === 'INVESTMENT'"> ({{getEnumName({value: 'accountInvestmentType'}, item.accountInvestmentType)}})</span>
        </template>
        <template v-slot:item.emailHookId="{ value, header }">
            <div style="font-size: x-small">
              {{getEnumName(header, value)}}
            </div>
        </template>
        <template v-slot:item.investmentPurposeId="{ header, item }">
          <div v-if="item.forEnvelopes">
            Envelopes
          </div>
          <div v-else>
            {{getEnumName(header, item.investmentPurposeId)}}
          </div>
        </template>
        <template v-slot:item.accountActions="{ item }">
          <v-btn small icon :elevation="0" :to="'transactions/a/' + item.id">
            <v-icon>mdi-email-newsletter</v-icon>
          </v-btn>
          <v-btn v-if="item.type !== 'INVESTMENT' && item.type !== 'TRANSFER'" small icon :elevation="0" :to="'transactions/t/' + item.id">
            <v-icon>mdi-cash-check</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.defaultIncomeSourceId="{ value, header }">
          {{getEnumName(header, value)}}
        </template>
        <!-- END of templates we shouldn't have    -->
        <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
          <slot :name="slot" v-bind="props" />
        </template>
      </v-data-table>

      <v-card-actions v-if="!hideFilter">
        <v-spacer v-if="!showFilterBox"/>
        <v-text-field
          v-if="showFilterBox"
          v-model="filter"
          label="Filter"
          single-line
          hide-details
        ></v-text-field>
        <v-btn color="primary" fab :elevation="0" @click="filter = ''; showFilterBox = !showFilterBox;">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

    </div>
    <div v-else>
      <div v-if="emptyTableText">{{emptyTableText}}</div>
      <div v-else>No rows.</div>
    </div>

  </div>
</template>

<script>
import DynamicForm from '@/components/DynamicForm'
export default {
  name: 'EditableTable',
  components: { DynamicForm },
  data: () => ({
    objects: [],
    expanded: [],
    filter: "",
    reloadForm: false,
    subscriptions: [],
    now: Date.now(),
    nowUpdaterInterval: null,
    showFilterBox: false,
  }),
  props: {
    fields: Array,
    // objects: Array,
    inputObjects: Array,

    // Can we remove these?
    objectSource: Function,
    objectSubscription: Function,
    //

    tableSubscription: Function,
    saveMethod: Function,
    saveText: String,
    successCallback: Function,
    cancelCallback: Function,
    showHeaderDivider: Boolean,
    emptyTableText: String,
    objectKey: {
      type: String,
      default: "id"
    },
    hideFilter: Boolean,
    validateRows: Boolean
  },

  computed: {
    viewFields() {
      const headers = [];
      for (const field of this.fields) {
        if (!field.hidden) {
          const header = {
            text: field.displayName,
            value: field.fieldName,
            width: field.width,
          };

          if (field.type === Boolean)
            header.align = 'center';

          headers.push(header);
        }
      }
      headers.push({ text: '', value: 'data-table-expand' });

      return headers;
    },
    editFields() {
      return this.fields.filter(field => {
        return field.editable;
      });
    }
  },

  watch: {
    expanded() {
      this.reloadForm = !this.reloadForm;
    }
  },

  methods: {
    getEnumName: function(header, item) {
      let name = item;
      this.fields.forEach(field => {
        if (field.fieldName === header.value) {
          field.values.forEach(entry => {
            if (entry.value === item)
              name = entry.text;
          });
        }
      })
      return name;
    },
    successDoneEditing: function() {
      this.reloadForm = !this.reloadForm;
      this.expanded = [];
      this.reloadData();
      if (this.successCallback)
        this.successCallback();
    },
    cancelEditing() {
      this.expanded = [];
      if (this.cancelCallback)
        this.cancelCallback();
    },
    reloadData() {
      if (this.objectSource) {
        this.objectSource()
          .then((objects) => {
            this.objects = objects;
            this.objects.forEach(this.subscribe)
          })
          .catch(() => {
            this.objects = [];
          });
      } else if (this.tableSubscription) {
        this.subscriptions.push(this.tableSubscription(this.updateObject));
      } else if (this.inputObjects) {
        this.objects = this.inputObjects;
      }
    },
    updateObject(newObject) {
      let index = null;
      for (let i = 0; i < this.objects.length; i++)
        if (this.objects[i][this.objectKey] === newObject[this.objectKey])
          index = i;
      if (index !== null) {
        // this.objects[index] = newObject; // We must splice so vue will see the changes
        this.objects.splice(index, 1, newObject);
      } else {
        this.objects.push(newObject);
      }
    },
    subscribe(originalObject) {
      if (this.objectSubscription && originalObject) {
        this.subscriptions.push(this.objectSubscription(originalObject.id, this.updateObject));
      }
    },
    getClass(item) {
      if (this.validateRows) {
        let areAllFieldsValid = true;
        if (this.editFields) {
          this.editFields.forEach(field => {
            if (field.rules && field.type === 'NamedCurrencyList' && item[field.fieldName]) {
              item[field.fieldName].forEach(subField => {
                field.rules.forEach(rule => {
                  if (rule(subField.name) !== true)
                    areAllFieldsValid = false;
                  if (rule(subField.amount) !== true)
                    areAllFieldsValid = false;
                });
              });
            } else
            if (field.rules) {
              field.rules.forEach(rule => {
                if (rule(item[field.fieldName]) !== true)
                  areAllFieldsValid = false;
              });
            }
          });
        }

        if (!areAllFieldsValid)
          return 'errorRow';
      }
    }
  },
  mounted() {
    this.reloadData();
    this.subscribe();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    this.nowUpdaterInterval = setInterval(function () {
      me.now = Date.now() + 1000;
    }, 1000)
  },
  destroyed() {
    this.subscriptions.forEach(subscription => {
      this.$api.unsubscribe(subscription);
    });
    clearInterval(this.nowUpdaterInterval);
  }
}
</script>

<style>
.shortColumn {
  max-width: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.errorRow {
  background-color: #440000;
  /*border-radius: 8px;*/
}
th {
  white-space: pre;
}

/*tr td:first-of-type {*/
/*  border-top-left-radius: 8px;*/
/*  border-bottom-left-radius: 8px;*/
/*}*/

/*tr td:last-of-type {*/
/*  border-top-right-radius: 8px;*/
/*  border-bottom-right-radius: 8px;*/
/*}*/
</style>
