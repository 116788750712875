<template>
  <div>
    <Card title="Basic Information" icon="mdi-home">
      <EditableObject
        :object="household"
        :fields="[{fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[rules.required]}]"
        :save-method="saveHousehold"
      />
      <br/>
      <ButtonWithConfirmation
        :confirmation-text='"Are you sure you want to delete \"" + household.name + "\"?"'
        confirmation-icon="mdi-delete"
        button-text="Delete"
        button-color="error"
        :action="() => $api.household.deleteHousehold(household.id)"
        :success-callback="() => $router.push('/')"
      />
    </Card>
    <Permissions :id="household.id" type="household" showHeaderDivider/>
  </div>
</template>

<script>
  import EditableObject from '@/components/EditableObject'
  import Card from '@/components/Card'
  import ButtonWithConfirmation from '@/components/ButtonWithConfirmation'
  import Permissions from '@/components/Permissions'
  export default {
    name: 'Settings',
    components: { Permissions, ButtonWithConfirmation, Card, EditableObject },
    props: ['household'],

    data: () => ({
      rules: {
        required: value => !!value || 'Required',
      }
    }),

    methods: {
      saveHousehold(household) {
        return this.$api.household.saveHousehold(household);
      },
    },

    mounted() {
      this.$emit('breadcrumbs', []);
      this.$emit('resetMenu');
    },
  }
</script>

<style scoped>

</style>
