import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list)?_c('Card',{class:{'card-height-for-buttons':_vm.showHelp},attrs:{"icon":_vm.readOnly ? 'mdi-alpha-' + _vm.list.name.substr(0, 1).toLowerCase() + '-box' : 'mdi-clipboard-check',"title":_vm.list.name,"actions":_vm.readOnly ? undefined : [
        {text:(_vm.showButtons ? 'Hide' : 'Show') + ' Buttons', click:_vm.toggleButtons, icon:'mdi-gesture-tap-button'},
        {text:(_vm.showHelp ? 'Hide' : 'Show') + ' Shortcuts', click:_vm.toggleHelp, icon:'mdi-keyboard'},
        {text:'Delete All Completed', click:_vm.wipe, icon:'mdi-delete-sweep'},
        {text:'Send List', click:_vm.sendList, icon:'mdi-send'},
      ],"launch-to":_vm.readOnly ? '/h/' + _vm.household.id + '/list/' + _vm.listHolder.id + '/' + _vm.list.id : null}},[(_vm.showHelp)?_c('div',{staticStyle:{"position":"absolute","top":"52px","right":"16px"}},[_vm._v(" ↑ - Up"),_c('br'),_vm._v(" ↓ - Down"),_c('br'),_vm._v(" ⌘↑ - Move Up"),_c('br'),_vm._v(" ⌘↓ - Move Down"),_c('br'),_vm._v(" e / F2 - Edit"),_c('br'),_vm._v(" Spacebar - Complete/open"),_c('br'),_vm._v(" Enter - Save/New task below"),_c('br'),_vm._v(" Escape - Cancel"),_c('br'),_vm._v(" Shift Enter - New task above"),_c('br')]):_vm._e(),(_vm.newItemAbove)?_c(VTextField,{attrs:{"outlined":"","autofocus":"","dense":"","hide-details":"","disabled":_vm.newItemAbove.saving,"append-icon":_vm.showButtons ? 'mdi-close-circle' : '',"append-outer-icon":_vm.showButtons ? 'mdi-content-save' : '',"spellcheck":true},on:{"click:append":_vm.clearItemAbove,"click:append-outer":_vm.saveItemAbove},model:{value:(_vm.newItemAbove.text),callback:function ($$v) {_vm.$set(_vm.newItemAbove, "text", $$v)},expression:"newItemAbove.text"}}):_vm._e(),_c('draggable',{attrs:{"disabled":_vm.newItemAbove || _vm.editItem || _vm.newItemBelow || _vm.readOnly},on:{"end":_vm.savePositions},model:{value:(_vm.listItems),callback:function ($$v) {_vm.listItems=$$v},expression:"listItems"}},_vm._l((_vm.listItems),function(item,index){return _c('div',{key:index},[(_vm.editItem && _vm.editItem.id === item.id)?_c('div',{staticClass:"py-1"},[_c(VTextField,{attrs:{"outlined":"","autofocus":"","dense":"","hide-details":"","disabled":_vm.editItem.saving,"append-icon":_vm.showButtons ? 'mdi-close-circle' : '',"append-outer-icon":_vm.showButtons ? 'mdi-content-save' : '',"spellcheck":true},on:{"click:append":_vm.clearEditItem,"click:append-outer":_vm.saveEditItem},model:{value:(_vm.editItem.text),callback:function ($$v) {_vm.$set(_vm.editItem, "text", $$v)},expression:"editItem.text"}})],1):(item)?_c('div',{class:'px-3 py-1 subtitle-1' +
    (_vm.selectedItemId == item.id ? ' primary' : '')
     + (item.closedTimestamp ? ' closedItem' : ''),staticStyle:{"border-radius":"4px","height":"40px","align-items":"center","display":"flex"},on:{"mousedown":function($event){!_vm.readOnly && _vm.clickItem(item.id)}}},[(_vm.showButtons)?_c('span',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openCloseItem(item)}}},[(item.closedTimestamp)?_c(VIcon,[_vm._v("mdi-checkbox-marked-outline")]):_c(VIcon,[_vm._v("mdi-checkbox-blank-outline")])],1):_vm._e(),_c('span',{style:(item.closedTimestamp ? 'text-decoration: line-through;' : '')},[_vm._v(" "+_vm._s(item.text)+" ")]),_c(VSpacer),(_vm.showButtons)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editThisItem(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1):_vm._e()],1):_vm._e(),(_vm.newItemBelow && ((item.position + 1) === _vm.newItemBelow.position) )?_c(VTextField,{attrs:{"outlined":"","autofocus":"","dense":"","hide-details":"","disabled":_vm.newItemBelow.saving,"append-icon":_vm.showButtons ? 'mdi-close-circle' : '',"append-outer-icon":_vm.showButtons ? 'mdi-content-save' : '',"spellcheck":true},on:{"click:append":_vm.clearItemBelow,"click:append-outer":_vm.saveItemBelow},model:{value:(_vm.newItemBelow.text),callback:function ($$v) {_vm.$set(_vm.newItemBelow, "text", $$v)},expression:"newItemBelow.text"}}):_vm._e()],1)}),0),(_vm.showButtons)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","fab":"","elevation":0},on:{"click":_vm.addItemAtEnd}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }