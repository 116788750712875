<template>
  <div>
    <Card icon="mdi-bank" title="Accounts" wide>
      <EditableTable
        empty-table-text="No accounts have been created (yet)."
        :hideFilter=true
        :validateRows=true
        :inputObjects="accounts"
        :fields="accountFields"
        :save-method="(data) => this.$api.financial_account.saveAccount(data)"
      />
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" fab :elevation="0" @click="showCreateAccountDialog = true">
          <v-icon>mdi-bank-plus</v-icon>
        </v-btn>
        <v-btn color="primary" fab :elevation="0" disabled>
          <v-icon>mdi-bank-transfer-out</v-icon>
        </v-btn>
      </v-card-actions>
      <div>* If the balance does NOT reflect an accurate balance, check for transaction errors or adjust your initial transaction by clicking
        <v-icon>mdi-cash-check</v-icon>.
      </div>
      <div>* New transactions before the initial transaction date will be placed in "Pre-Envelopes" instead of "Uncategorized".</div>
    </Card>

    <v-dialog v-model="showCreateAccountDialog" max-width="500px">
      <Card title="Create Account" icon="mdi-bank-plus" inDialog>
        <DynamicForm
          :key="showCreateAccountDialog"
          :object="{financialModuleId: financialModule.id, forEnvelopes: true, emailHookId: null, initialTransactionDate: new Date(new Date() - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0]}"
          :fields="[
          {fieldName:'name', displayName: 'Name', placeholderFieldName: 'officialName', type: String, rules:[rules.required]},
          {fieldName:'type', displayName: 'Type', type: 'Enumerator', values: getAvailableTypes(getEnumArray(accountType)), rules:[rules.required]},
          {fieldName:'accountInvestmentType', displayName: 'Investment Type', type: 'Enumerator', values: getEnumArray(accountInvestmentType), displayCondition: o => o.type === 'INVESTMENT' },
          {fieldName:'creditLimit', displayName: 'Credit Limit', type: 'Currency', displayCondition: o => o.type === 'CREDIT' },
          {fieldName:'accountNumber', displayName: 'Last 4 Digits of Account Number for Sync', type: String, maxlength: 4, width: '50px'},
          {fieldName:'nameForSync', displayName: 'Account Name for Sync', type: String},
          {fieldName:'forEnvelopes', displayName: 'Use for Envelopes', type: Boolean, displayCondition: o => (o.type !== 'LOAN' && o.type !== 'INVESTMENT')},
          {fieldName:'investmentPurposeId', displayName: 'Purpose', type: 'Enumerator', values: investmentPurposesForDropdown, displayCondition: o => (!o.forEnvelopes || o.type === 'INVESTMENT'), addAction: (o) => this.showCreateInvestmentPurpose(o) },
          {fieldName:'initialTransactionDate', displayName: 'Initial Transaction Date', type: 'LocalDate', small: true, displayCondition: o => (o.forEnvelopes && o.type !== 'INVESTMENT')},
          {fieldName:'emailHookId', displayName: 'Email Hook', type: 'Enumerator', values: emailHooks, addAction: (o) => addEmailHook(o)},

            ]"
          :save-method="(data) => {
              return this.$api.financial_account.createAccount(data, data.initialTransactionDate);
            }"
          :cancel-callback="() => this.showCreateAccountDialog = false"
          :success-callback="(o) => {this.showCreateAccountDialog = false}"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>

    <v-dialog v-model="showCreateInvestmentPurposeDialog" max-width="500px">
      <Card title="New Purpose" icon="mdi-piggy-bank" inDialog>
        <DynamicForm
          :key="showCreateInvestmentPurposeDialog"
          :object="{}"
          :fields="[
          {fieldName:'name', displayName: 'Name', type: String, rules:[rules.required]},
            ]"
          :save-method="(data) => {
              return this.$api.financial_investments.createInvestmentPurpose(financialModule.id, data.name);
            }"
          :cancel-callback="() => this.showCreateInvestmentPurposeDialog = false"
          :success-callback="(o) => {
            this.createInvestmentPurposeAccount.investmentPurposeId = o.id;
            this.showCreateInvestmentPurposeDialog = false;
          }"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
import Card from '@/components/Card'
import EditableTable from '@/components/EditableTable'
import { AccountType, AccountInvestmentType } from '@/api'
import DynamicForm from '@/components/DynamicForm'

export default {
  name: 'Accounts',
  components: { DynamicForm, EditableTable, Card },
  props: ['household', 'financialModule', 'accounts', 'investmentPurposes', 'incomeSources'],

  data: () => ({
    accountType: AccountType,
    accountInvestmentType: AccountInvestmentType,
    emailHooks: [],
    showCreateAccountDialog: false,
    showCreateInvestmentPurposeDialog: false,
    createInvestmentPurposeAccount: null,

    rules: {
      required: value => !!value || 'Required',
    }
  }),
  methods: {
    getAvailableTypes(items) {
      items.forEach(item => {
        if (item.value === 'TRANSFER')
          item.disabled = true;
      });
      return items;
    },
    loadEmailHooks() {
      this.$api.financial.readFinancialModuleEmailHooks(this.financialModule.id).then(hooks => {
        const newHooks = [{ text: "None", value: null }];
        hooks.forEach(hook => {
          newHooks.push({
            text: hook.emailAddress,
            value: hook.id
          })
        });
        this.emailHooks = newHooks;
      });
    },
    addEmailHook(object) {
      this.$api.financial.generateFinancialModuleEmailHooks(this.financialModule.id, 'Financial Email Hook').then((hook) => {
        this.emailHooks.push({
          text: hook.emailAddress,
          value: hook.id
        });
        object.emailHookId = hook.id;
      });
    },
    showCreateInvestmentPurpose(object) {
      this.showCreateInvestmentPurposeDialog = true;
      this.createInvestmentPurposeAccount = object;
    },
  },
  computed: {
    investmentPurposesForDropdown() {
      const items = [];
      this.investmentPurposes.forEach(purpose => {
        items.push({
          text: purpose.name,
          value: purpose.id
        });
      })
      return items;
    },
    incomeSourcesForDropdown() {
      const items = [];
      this.incomeSources.forEach(item => {
        items.push({
          text: item.name,
          value: item.id
        });
      });
      return items;
    },
    accountFields() {
      const fields =
        [
          // {fieldName:'id', displayName: 'Id', type: String},
          { fieldName: 'name', displayName: 'Name', placeholderFieldName: 'officialName', editable: true, type: String, rules: [this.rules.required], displayCondition: o => o.type !== 'TRANSFER' },
          { fieldName: 'type', displayName: 'Type', editable: true, readOnly: true, type: 'Enumerator', values: this.getEnumArray(this.accountType) },
          { fieldName: 'accountInvestmentType', displayName: 'Investment Type', hidden: true, editable: true, type: 'Enumerator', values: this.getEnumArray(this.accountInvestmentType), displayCondition: o => o.type === 'INVESTMENT' },
        ];

      let showNumber = false;
      let showEmailHook = false;
      this.accounts.forEach(account => {
        if (account.accountNumber)
          showNumber = true;
        if (account.emailHookId)
          showEmailHook = true;
      });

      fields.push({ fieldName: 'accountNumber', displayName: 'Number', hint: 'Last 4 Digits of Account Number for Sync', hidden: !showNumber, editable: true, type: String, maxlength: 4, width: '50px', displayCondition: o => o.type !== 'TRANSFER' });

      fields.push({ fieldName: 'nameForSync', displayName: 'Account Name for Sync', hidden: true, editable: true, type: String, displayCondition: o => o.type !== 'TRANSFER' });
      fields.push({ fieldName: 'investmentPurposeId', displayName: 'Purpose', editable: true, type: 'Enumerator', values: this.investmentPurposesForDropdown, displayCondition: o => !o.forEnvelopes, addAction: (o) => this.showCreateInvestmentPurpose(o) });
      fields.push({ fieldName: 'creditLimit', displayName: 'Credit Limit', type: 'Currency', hidden: true, editable: true, displayCondition: o => o.type === 'CREDIT' });

      fields.push({ fieldName: 'emailHookId', displayName: 'Email Hook', type: 'Enumerator', hidden: !showEmailHook, editable: true, values: this.emailHooks, displayCondition: o => o.type !== 'TRANSFER', addAction: (o) => this.addEmailHook(o) });

      fields.push({ fieldName: 'lastFullSync', displayName: 'Last Sync', editable: false, readOnly: true });

      fields.push({ fieldName: 'balanceWithPending', displayName: 'Balance (Pending)\nAvailable Credit', editable: false, readOnly: true, type: 'Currency' });
      fields.push({ fieldName: 'defaultIncomeSourceId', displayName: 'Default Income Source', type: 'Enumerator', values: this.incomeSourcesForDropdown, editable: true, clearable: true });
      fields.push({ fieldName: 'accountActions', displayName: '', editable: false, width: '88px' });

      return fields;
    }
  },
  mounted() {
    this.$emit('breadcrumbs', [
      {
        text: 'Accounts',
        icon: 'mdi-bank',
        to: '/h/' + this.household.id + '/f/' + this.financialModule.id + '/accounts'
      }
    ]);
    this.loadEmailHooks();
  },
}
</script>

<style scoped>

</style>
