<template>
  <div>
    <v-btn :color="buttonColor" @click="showDialog = true" :small="small">
      {{buttonText}}
    </v-btn>

    <v-dialog v-model="showDialog" max-width="600" :persistent="isDoingAction">
      <Card
        :icon="confirmationIcon"
        :title="confirmationText"
        inDialog
      >
        <v-card-actions>
          <v-spacer />
          <v-btn @click="showDialog = false" :disabled="isDoingAction">Cancel</v-btn>
          <v-btn :color="buttonColor" @click="doAction" :loading="isDoingAction">
            {{buttonText}}
          </v-btn>
        </v-card-actions>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
  import Card from '@/components/Card'
  export default {
    name: 'ButtonWithConfirmation',
    components: { Card },
    props: {
      confirmationText: String,
      confirmationIcon: String,
      buttonText: String,
      buttonColor: String,
      action: Function,
      successCallback: Function,
      small: Boolean,
    },

    data: () => ({
      showDialog: false,
      isDoingAction: false
    }),

    methods: {
      doAction() {
        this.isDoingAction = true;
        const result = this.action();

        if (result.then) {
          result.then(() => {
            this.isDoingAction = false;
            this.showDialog = false;
            if (this.successCallback)
              this.successCallback();
          });
          result.catch(() => {
            this.isDoingAction = false;
          });
        } else {
          this.isDoingAction = false;
          this.showDialog = false;
          if (this.successCallback)
            this.successCallback();
        }
      }
    }
  }
</script>

<style scoped>

</style>
