<template>
  <Card title="Permissions" icon="mdi-shield-account">
    <v-expansion-panels v-if="permissions" v-model="panel" focusable flat tile inset>
      <v-expansion-panel
        v-for="(authority, index) in permissions.authorities"
        v-bind:key="authority.id"
      >
        <v-divider v-if="index > 0"/>
        <v-expansion-panel-header
          hide-actions
          class="pa-0"
          v-slot="{ open }"
        >
          <v-list-item
            :inactive="open"
            :ripple="false"
          >
            <v-list-item-content class="text-uppercase text--secondary">{{ authority.permissionLevel }}<span v-if="authority.inherited">(Inherited)</span></v-list-item-content>
            <v-list-item-content class="subtitle-1">{{ authority.user.name }} ({{authority.user.emailAddress}})</v-list-item-content>
            <v-list-item-icon><v-icon v-if="!open">mdi-pencil</v-icon></v-list-item-icon>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list-item inactive :ripple="false">
            <v-list-item-content class="text-uppercase text--secondary">Granted by</v-list-item-content>
            <v-list-item-content class="subtitle-1">{{ authority.grantedByUser.name }} ({{authority.grantedByUser.emailAddress}})</v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-list-item inactive :ripple="false">
            <v-list-item-content class="text-uppercase text--secondary">Granted on</v-list-item-content>
            <v-list-item-content class="subtitle-1">{{moment(authority.grantedTimestamp)}}</v-list-item-content>
          </v-list-item>
          <div v-if="activeOwner">
            <v-divider/>
            <v-list-item inactive :ripple="false">
              <v-list-item-content class="text-uppercase text--secondary">Permission Level</v-list-item-content>
              <v-list-item-content class="subtitle-1">
                <v-select v-model="authority.newPermissionLevel" :items="Object.values(PermissionLevels)" ></v-select>
              </v-list-item-content>
            </v-list-item>
            <!--            <v-divider/>-->
            <v-card-actions>
              <ButtonWithConfirmation
                :confirmation-text='"Are you sure you want to revoke this permission?"'
                confirmation-icon="mdi-delete"
                button-text="Revoke"
                button-color="error"
                :action="() => $api.permission.revokeAuthority(authority.id)"
                :success-callback="() => panel = []"
              />
              <v-spacer />

              <v-btn style="margin: 8px;" @click="() => {authority.newPermissionLevel = authority.permissionLevel; for( var i = 0; i < panel.length; i++){if ( panel[i] === index) panel.splice(i, 1);}}">Cancel</v-btn>
              <ButtonWithConfirmation
                :confirmation-text='"Are you sure you want to change this permission?"'
                confirmation-icon="mdi-delete"
                button-text="Save"
                button-color="primary"
                :action="() => $api.permission.changePermissionLevel(authority.id, authority.newPermissionLevel)"
                :success-callback="() => panel = []"
              />
            </v-card-actions>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="false">
        <v-divider v-if="permissions.authorities.length > 0"/>
        <v-expansion-panel-header hide-actions class="pa-0" v-slot="{ open }">
          <v-list-item :inactive="open" :ripple="false">
            <v-list-item-content class="text-uppercase text--secondary">Invite User</v-list-item-content>
            <v-list-item-content class="subtitle-1"></v-list-item-content>
            <v-list-item-icon><v-icon>mdi-shield-plus</v-icon></v-list-item-icon>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <DynamicForm
            :key="panel.length"
            :object="{}"
            :fields="[
              {fieldName:'email', displayName:'Email', type:String, rules:[rules.required, rules.email]},
              {fieldName:'permissionLevel', displayName:'Permission Level', type:'Enumerator', rules:[rules.required], values:Object.values(PermissionLevels)},
              ]"
            :focusIndex="0"
            :save-method="(data) => this.$api.permission.inviteUser(id, type, data.email, data.permissionLevel)"
            :cancel-callback="() => panel = []"
            :success-callback="() => panel = []"
            saveText="Invite"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </Card>
</template>

<script>
import Card from '@/components/Card'
import ButtonWithConfirmation from '@/components/ButtonWithConfirmation'
import DynamicForm from '@/components/DynamicForm'
import { PermissionLevel } from '@/api'
export default {
  name: 'Permissions',

  components: { DynamicForm, ButtonWithConfirmation, Card },

  props: {
    id: String,
    type: String,
  },

  data: () => ({
    panel: [],
    subscription: null,
    permissions: null,
    activeOwner: false,
    reloadInviteForm: false,
    PermissionLevels: PermissionLevel,
    rules: {
      required: value => !!value || 'Required',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid email'
      },
    }
  }),

  methods: {
    updatePermissions: function(permissions) {
      this.activeOwner = false;
      permissions.authorities.forEach(permission => {
        permission.newPermissionLevel = permission.permissionLevel;
        if (!permission.inherited && permission.user.id === this.$store.state.user.id && permission.permissionLevel === PermissionLevel.OWNER)
          this.activeOwner = true;
      });
      this.permissions = permissions;
    },

  },

  mounted () {
    this.subscription = this.$api.permission.subscribe(this.type + ":" + this.id, this.updatePermissions);
  },

  destroyed () {
    if (this.subscription)
      this.$api.unsubscribe(this.subscription);
  }
}
</script>

<style scoped>
</style>
