<template>
  <Card icon="mdi-format-list-checkbox" title="Action Items" :style="(show ? '' : 'display: none;')">
    <ActionItems :module="{actionItemsId: type + ':' + id}" v-on:show="show = $event"/>
  </Card>
</template>

<script>
import ActionItems from '@/hoamodules/ActionItems.vue'
import Card from '@/components/Card.vue'

export default {
  name: 'ActionItemsCard',
  components: { Card, ActionItems },
  props: ['type', 'id'],

  data: () => ({
    show: false,
  }),
}
</script>

<style scoped>

</style>
