var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',{attrs:{"title":"Artifact Repository","icon":"mdi-file-cog","wide":""}},[_c('EditableTable',{attrs:{"table-subscription":(handler) => this.$api.webrepo.subscribeAll(handler),"fields":[
        {fieldName:'id', displayName: 'Id'},
        {fieldName:'name', displayName: 'Name'},
        {fieldName:'releaseChannel', displayName: 'Release Channel'},
        {fieldName:'commit', displayName: 'Commit'},
        {fieldName:'createdTimestamp', displayName: 'Created'},
        {fieldName:'hash', displayName: 'Hash'},
        {fieldName:'fileId', displayName: 'FileId'},
        ]},scopedSlots:_vm._u([{key:"item.commit",fn:function({ item }){return [(item.commit === 'dev' || item.commit.length == 14)?_c('div',[_vm._v(_vm._s(item.commit))]):_c('a',{attrs:{"href":'https://gitlab.com/parry_household/hoa/-/commit/' + item.commit,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(item.commit))])]}},{key:"item.fileId",fn:function({ item }){return [_c('div',{staticClass:"shortColumn"},[_c('a',{attrs:{"href":'/api/repo/' + item.releaseChannel + '/' + item.name,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(item.fileId))])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }