<template>
  <div>
    <Card v-for="purpose in investmentPurposes" :key="purpose.id"
          :title="purpose.name" icon="mdi-piggy-bank"
          :actions="[{icon: 'mdi-refresh', click: () => reload++}]"
    >
      <Purpose
        :key="reload"
        :purpose="purpose"
        :accounts="accounts"
        :assetClassesForDropdown="assetClassesForDropdown"
        :assetClasses="assetClasses"
        v-on:addAssetClass="addAssetClass($event)"
        v-on:saved="() => reload++"
      />
    </Card>

    <Card title="Securities" icon="mdi-finance" collapsible>
      <v-data-table
        v-if="mySecurities"
        :key="reloadMySecuritiesForm"
        :items="mySecurities"
        :headers="securitiesHeaders"
        :expanded.sync="mySecuritiesExpanded"
        show-expand
        single-expand
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.securityId="{ item }">
          <div v-if="item.securityId && securities[item.securityId]">
            {{ securities[item.securityId].name }}
            <span> ({{ securities[item.securityId].symbol }})</span>
          </div>
        </template>
        <template v-slot:item.expenseRatio="{ item }">
          <div v-if="item.expenseRatio">
            {{ formatPercent(item.expenseRatio) }}
          </div>
          <div v-else-if="securities[item.securityId]">
            {{ formatPercent(securities[item.securityId].expenseRatio) }}
          </div>
        </template>
        <template v-slot:item.assetAllocations="{ item }">
          <div v-if="item.assetAllocations && item.assetAllocations">
            <table v-if="item.assetAllocations">
              <tr v-for="(allocation, i) in item.assetAllocations" :key="i">
                <td>
                  <span v-if="assetClasses[allocation.assetClassId]">{{ assetClasses[allocation.assetClassId].name }}</span>
                </td>
                <td>{{formatPercent(allocation.weight)}}</td>
              </tr>
            </table>
          </div>
          <div v-else-if="item.securityId && securities[item.securityId]">
            <table v-if="securities[item.securityId].assetAllocations">
              <tr v-for="(allocation, i) in securities[item.securityId].assetAllocations" :key="i">
                <td>
                  <span v-if="assetClasses[allocation.assetClassId]">{{ assetClasses[allocation.assetClassId].name }}</span>
                </td>
                <td>{{formatPercent(allocation.weight)}}</td>
              </tr>
            </table>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <DynamicForm
              :object="{
                name: item.name,
                syncKey: item.syncKey,
                securityId: item.securityId,
                useDefaultAssetAllocations: item.securityId && !item.assetAllocations,
                allocations: item.assetAllocations || (item.securityId && securities[item.securityId] && securities[item.securityId].assetAllocations ? securities[item.securityId].assetAllocations : []),
                defaultAllocations: item.securityId && securities[item.securityId] ? securities[item.securityId].assetAllocations : [],
              }"
              :fields="[
                { fieldName: 'name', displayName: 'Name', type:String, rules:[rules.required]},
                { fieldName: 'syncKey', displayName: 'Key', type:String, readOnly: true},
                { fieldName: 'securityId', displayName: 'Security', type:'Enumerator', values: securitiesForDropdown},
                { fieldName: 'useDefaultAssetAllocations', displayName: 'Default Asset Allocations', type:Boolean, displayCondition: o => o.securityId},
                { fieldName: 'defaultAllocations', displayName: 'Allocations', type:'AssetAllocationList', readOnly: true, values: assetClassesForDropdown, displayCondition: o => o.securityId && o.useDefaultAssetAllocations},
                { fieldName: 'allocations', displayName: 'Allocations', type:'AssetAllocationList', values: assetClassesForDropdown, addAction: (o) => addAssetClass(o), displayCondition: o => !o.securityId || !o.useDefaultAssetAllocations, rules:[rules.required]},
              ]"
              :saveMethod="(o) => $api.financial_investments.saveFinancialModuleSecurity(financialModule.id, item.id, item.version, o.securityId, o.name, o.useDefaultAssetAllocations, o.allocations)"
              :cancelCallback="() => mySecuritiesExpanded = []"
              :successCallback="() => mySecuritiesExpanded = []"
              v-on:securityIdchange="$event.defaultAllocations = $event.securityId && securities[$event.securityId] ? securities[$event.securityId].assetAllocations : []"
            >
            </DynamicForm>
          </td>
        </template>
      </v-data-table>
    </Card>

    <v-dialog v-model="showCreateAssetClassDialog" max-width="500px">
      <Card title="New Asset Class" icon="mdi-plus" inDialog>
        <DynamicForm
          :key="showCreateAssetClassDialog"
          :object="{}"
          :fields="[
          {fieldName:'name', displayName: 'Name', type: String, rules:[rules.required]},
            ]"
          :save-method="(data) => {
              return this.$api.financial_investments.createAssetClass(financialModule.id, data.name);
            }"
          :cancel-callback="() => this.showCreateAssetClassDialog = false"
          :success-callback="(o) => {
            this.showCreateAssetClassDialog = false;
          }"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
import Card from '@/components/Card'
import DynamicForm from '@/components/DynamicForm.vue'
import Purpose from '@/hoamodules/financial/Purpose.vue'
export default {
  name: 'Investing',
  components: { Purpose, DynamicForm, Card },
  props: ['household', 'financialModule', 'accounts', 'envelopes', 'investmentPurposes', 'mySecurities'],
  data: () => ({
    rules: {
      required: value => !!value || 'Required',
    },

    reload: 0,

    showCreateAssetClassDialog: false,

    mySecuritiesExpanded: [],
    reloadMySecuritiesForm: false,
    securities: { },
    securitiesSubscription: null,
    securitiesHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Security Key', value: 'syncKey' },
      { text: 'Security', value: 'securityId' },
      { text: 'ER', value: 'expenseRatio' },
      { text: 'Allocations', value: 'assetAllocations' },
      { text: '', value: 'data-table-expand' },
    ],

    myAssetClassesSubscription: null,
    assetClassesSubscription: null,
    assetClasses: { },
  }),

  watch: {
    mySecuritiesExpanded() {
      this.reloadMySecuritiesForm = !this.reloadMySecuritiesForm;
    }
  },

  methods: {
    addAssetClass(object) {
      this.showCreateAssetClassDialog = true;
    }
  },
  computed: {
    assetClassesForDropdown() {
      const parsableArray = [];
      for (const enumValue in this.assetClasses) {
        parsableArray.push({
          text: this.assetClasses[enumValue].name,
          value: this.assetClasses[enumValue].id,
          order: this.assetClasses[enumValue].priority + (this.assetClasses[enumValue].financialModuleId ? 0 : 10000)
        });
      }

      parsableArray.sort((a, b) => a.order - b.order);
      return parsableArray;
    },
    securitiesForDropdown() {
      const parsableArray = [];
      for (const enumValue in this.securities) {
        parsableArray.push({
          text: this.securities[enumValue].name,
          value: this.securities[enumValue].id,
        });
      }
      return parsableArray;
    }
  },
  mounted() {
    this.$emit('breadcrumbs', [
      {
        text: 'Investing',
        icon: 'mdi-finance',
        to: '/h/' + this.household.id + '/f/' + this.financialModule.id + '/investing'
      }
    ]);

    this.securities = { };
    this.securitiesSubscription = this.$api.financial_investments.subscribeToSecurities(security => {
      // this.securities[security.id] = security;
      this.$set(this.securities, security.id, security);
    }).unsubscribed.then(() => {
      this.securities = null;
    });

    this.$api.financial_investments.getSecuritiesUsedByFinancialModule(this.financialModule.id).then(securities => {
      securities.forEach(security => {
        // this.securities[security.id] = security;
        this.$set(this.securities, security.id, security);
      });
    });

    this.assetClasses = { };
    this.myAssetClassesSubscription = this.$api.financial_investments.subscribeToFinancialModuleAssetClasses(this.financialModule.id, assetClass => {
      this.$set(this.assetClasses, assetClass.id, assetClass);
    });
    this.myAssetClassesSubscription = this.$api.financial_investments.subscribeToGlobalAssetClasses(assetClass => {
      this.$set(this.assetClasses, assetClass.id, assetClass);
    });
  },

  destroyed() {
    if (this.securitiesSubscription)
      this.$api.unsubscribe(this.securitiesSubscription);
    if (this.myAssetClassesSubscription)
      this.$api.unsubscribe(this.myAssetClassesSubscription);
    if (this.assetClassesSubscription)
      this.$api.unsubscribe(this.assetClassesSubscription);
  }
}
</script>

<style scoped>

</style>
