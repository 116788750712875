import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#546E7A',
        secondary: '#90A4AE',
        accent: '#2196F3',
      },
      light: {
        primary: '#2196F3',
        secondary: '#607D8B',
        accent: '#009688',
      }
    }

},
})
