<template>
  <div>
    <v-select
      v-model="filterId"
      label="Filter"
      item-value="id"
      :items="channels"
      clearable
    >
      <template v-slot:item="{ item }">
        <div>{{item.name}} ({{notificationChannelType[item.type]}})</div>
      </template>
      <template v-slot:selection="{ item }">
        <div>{{item.name}} ({{notificationChannelType[item.type]}})</div>
      </template>
    </v-select>
    <v-data-table
      v-if="templates"
      :items="templates"
      :headers="headers"
      hide-default-footer
      disable-pagination
      disable-sort
    >
      <template v-for="channel in channels" v-slot:[`item.${channel.id}`]="{ item, value }">
        <div :key="channel.id" v-if="!filterId || filterId === channel.id">
          <div v-if="!channel.verified"></div>
          <div v-else-if="!item.moduleClassId">
            <v-btn icon @click="$api.notifications.sendTestNotification(channel.id)">
              <v-icon>mdi-bell-ring</v-icon>
            </v-btn>
          </div>
          <div v-else-if="value">
            <div v-if="value.deletedTimestamp">
              <v-btn icon @click="$api.notifications.setNotificationDeleted(value, false)">
                <v-icon>mdi-checkbox-blank-outline</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn icon @click="$api.notifications.setNotificationDeleted(value, true)">
                <v-icon>mdi-checkbox-marked-outline</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <v-btn icon @click="$api.notifications.createNotificationSubscription(item.notificationTemplateId, item.moduleClassId, channel.id)">
              <v-icon>mdi-checkbox-blank-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </template>

      <template v-for="(channel, i) in channels" v-slot:[`header.${channel.id}`]="{ }">
        <div :key="channel.id">
          {{channel.name}} ({{notificationChannelType[channel.type]}})
          <div v-if="!channel.verified">
            <v-btn x-small color="primary" @click="notificationChannelToVerify = channel; showVerifyNotificationChannelDialog = true;">
              Verify
            </v-btn>
          </div>
          <div v-if="i !== 0">
          <v-btn x-small color="primary" @click="notificationChannelToVerify = channel; showEditNotificationChannelDialog = true;">
            Edit
          </v-btn>
        </div>
        </div>
      </template>

    </v-data-table>
    <v-card-actions>
      <v-spacer />
      <v-btn fab color="primary" @click="showCreateNotificationChannelDialog = true">
        <v-icon>mdi-bell-plus</v-icon>
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="showCreateNotificationChannelDialog" max-width="1023">
      <Card title="Create Notification Channel" icon="mdi-bell-plus" inDialog>
        <DynamicForm
          :key="showCreateNotificationChannelDialog"
          :object="{}"
          :fields="[
            {fieldName:'name', displayName:'Name', type: String, rules: [rules.required]},
            {fieldName:'type', displayName:'Type', type: 'Enumerator', values:getEnumArray(notificationChannelType), rules: [rules.required]},
            {fieldName:'address', displayName:'Email', type: String, rules: [rules.email], displayCondition: o => o.type === 'EMAIL'},
            {fieldName:'address', displayName:'Email', type: String, rules: [rules.email], displayCondition: o => o.type === 'SMS_EMAIL'},
            ]"
          save-text="Send Verification Code"
          :save-method="(data) => this.addNotificationChannel(data)"
          :cancel-callback="() => this.showCreateNotificationChannelDialog = false"
          :success-callback="() => this.showCreateNotificationChannelDialog = false"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>

    <v-dialog v-model="showVerifyNotificationChannelDialog" max-width="350px">
      <Card title="Verify Notification Channel" icon="mdi-bell" inDialog>
        <DynamicForm
          :key="showVerifyNotificationChannelDialog"
          :object="{}"
          :fields="[
            {fieldName:'verificationCode', displayName:'Verification Code', type: 'OTP', rules: [rules.required]},
            ]"
          :focusIndex="0"
          save-text="Verify"
          :delete-method="(data) => this.$api.notifications.deleteNotificationChannel(notificationChannelToVerify)"
          :save-method="(data) => this.$api.notifications.verifyNotificationChannel(notificationChannelToVerify, data.verificationCode)"
          :cancel-callback="() => this.showVerifyNotificationChannelDialog = false"
          :success-callback="() => this.showVerifyNotificationChannelDialog = false"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>

    <v-dialog v-model="showEditNotificationChannelDialog" max-width="350px">
      <Card title="Edit Notification Channel" icon="mdi-bell" inDialog>
        <DynamicForm
          :key="showEditNotificationChannelDialog"
          :object="notificationChannelToVerify"
          :fields="[
            {fieldName:'name', displayName:'Name', type: String, rules: [rules.required]},
            {fieldName:'address', displayName:'Address', type: String, readOnly: true, displayCondition: o => o.type === 'SMS_EMAIL' || o.type === 'EMAIL' },
            ]"
          :focusIndex="0"
          save-text="Save"
          :delete-method="(data) => this.$api.notifications.deleteNotificationChannel(notificationChannelToVerify)"
          :save-method="(data) => this.$api.notifications.updateNotificationChannel(data)"
          :cancel-callback="() => this.showEditNotificationChannelDialog = false"
          :success-callback="() => this.showEditNotificationChannelDialog = false"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>
  </div>

</template>

<script>
import { NotificationChannelType } from '@/api'
import Card from '@/components/Card.vue'
import DynamicForm from '@/components/DynamicForm.vue'

export default {
  name: 'NotificationSubscriptions',
  components: { DynamicForm, Card },

  data: () => ({
    notificationChannelType: NotificationChannelType,

    showCreateNotificationChannelDialog: false,
    showVerifyNotificationChannelDialog: false,
    showEditNotificationChannelDialog: false,
    notificationChannelToVerify: null,

    rules: {
      required: value => !!value || 'Required',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid email'
      },
    },

    channels: null,
    channelSubscription: null,
    templates: null,
    subscriptionsSubscription: null,
    filterId: null,
  }),

  computed: {
    headers() {
      const headers = [
        { text: 'Module Name', value: 'moduleClassname' },
        { text: 'Name', value: 'name' },
      ];
      if (this.channels) {
        this.channels.forEach(channel => {
          if (!this.filterId || this.filterId === channel.id)
            headers.push({ value: channel.id, text: channel.name + ' (' + this.notificationChannelType[channel.type] + ')' })
        });
      }
      return headers;
    },
    channelIds() {
      const ids = [];
      if (this.channels) {
        this.channels.forEach(channel => {
          ids.push(channel.id);
        });
      }
      return ids;
    }
  },

  methods: {

    randomNotification() {
      console.dir("randomNotification")
      // eslint-disable-next-line
      new Notification("Parry Copilot", {
        body: "body",
        icon: "https://localtest.parry.co/home-p-black-192.png",
        data: {
          url: "https://localtest.parry.co/h/afuqln0grdyflek5gkqymg/list/kdzuvrshqxaqkvxh6vnjeg"
        }
      });
    },
    addNotificationChannel(data) {
      if (data && data.type && data.type === 'PWA_PUSH') {
        const base64String = this.$store.state.user.config.applicationServerKey;
        // https://github.com/mdn/serviceworker-cookbook/blob/master/tools.js
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          // eslint-disable-next-line
          .replace(/\-/g, '+')
          .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }

        return new Promise((resolve, reject) => {
          Notification.requestPermission().then((result) => {
            if (result === "granted") {
              navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
                // serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
                // });
                serviceWorkerRegistration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: outputArray,
                }).then(
                  (pushSubscription) => {
                    data.address = JSON.stringify(pushSubscription);
                    this.$api.notifications.addNotificationChannel(data).then((result) => {
                      resolve();
                    });
                  },
                  (error) => {
                    this.$api.errorCallback("Could not create subscription. Result: " + error);
                    reject(new Error(error));
                  },
                );
              });
            } else {
              this.$api.errorCallback("Could not create notification. Result: " + result);
              reject(new Error(result));
            }
          });
        });
      } else {
        return this.$api.notifications.addNotificationChannel(data);
      }
    },
    unsubscribeAll() {
      if (this.channelSubscription)
        this.$api.unsubscribe(this.channelSubscription);
      this.channels = null;
      if (this.subscriptionsSubscription)
        this.$api.unsubscribe(this.subscriptionsSubscription);
      this.templates = null;
    },
    loadData() {
      this.unsubscribeAll();
      this.channels = [];
      this.channelSubscription = this.$api.notifications.subscribeToUserChannelsManageArray(this.$store.state.user.id, this.channels);
      this.$api.notifications.getNotificationTemplatesByModule().then(result => {
        this.templates = result;
        this.subscriptionsSubscription = this.$api.notifications.subscribeToUserNotificationSubscriptions(this.$store.state.user.id, subscription => {
          this.templates.forEach(template => {
            if (template.moduleClassId === subscription.moduleId && template.notificationTemplateId === subscription.notificationTemplateId) {
              this.$set(template, subscription.notificationChannelId, subscription);
            }
          });
        });
      });
    }
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.unsubscribeAll();
  }
}
</script>

<style scoped>

</style>
