<template>
  <router-view />
</template>

<script>
export default {
  name: 'Admin',
  components: { },
  mounted() {
    this.$emit('breadcrumbs', [{
      text: 'System',
      icon: 'mdi-cogs',
      to: '/admin'
    }]);
    this.$emit('menuItems', [
      {
        title: 'System Dashboard',
        icon: 'mdi-view-dashboard',
        to: '/admin'
      },
      {
        title: 'Households',
        icon: 'mdi-home-group',
        to: '/admin/households'
      },
      {
        title: 'Users',
        icon: 'mdi-account-cog',
        to: '/admin/users'
      },
      {
        title: 'Newtons',
        icon: 'mdi-router-network',
        to: '/admin/newtons'
      },
      {
        title: 'Repository',
        icon: 'mdi-file-cog',
        to: '/admin/repo'
      },
    ]);
  }
}
</script>

<style scoped>

</style>
