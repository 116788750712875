import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.chartData)?_c('LineChart',{attrs:{"data":_vm.chartData,"options":{responsive: true, stepped: true, elements: { line: {backgroundColor: 'white', borderColor: 'white', borderWidth: 1}, point:{ pointStyle: false}}, plugins: { tooltip: { callbacks: { label: _vm.graphLabel } } }, scales: { y: { ticks: { callback: function(value, index, ticks) {return _vm.formatMoney(value); } } }}}}}):_vm._e(),(_vm.holdings && _vm.holdings.length > 0)?_c(VDataTable,{attrs:{"items":_vm.holdingsToDisplay,"headers":[
      { value: 'accountId', text: 'Account' },
      { value: 'name', text: 'Name' },
      { value: 'quantity', text: 'Quantity' },
      { value: 'price', text: 'Price' },
      // { value: 'expenseRatio', text: 'ER' },
      { value: 'amount', text: 'Amount' },
    ],"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.accountId",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.getAccountName(value))+" ")]}},{key:"item.amount",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.price))+" ("+_vm._s(item.priceDate)+") ")]}},{key:"body.append",fn:function({ headers }){return [_c('tr',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_c('td',{attrs:{"colspan":headers.length - 2}}),_c('td',{staticClass:"text-right"},[_vm._v("Total")]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(_vm.formatMoney(_vm.total)))])])]}}],null,false,3086227184)}):_vm._e(),(_vm.purpose.allocations && _vm.purpose.allocations.length > 0 && _vm.allocations && _vm.allocations.length > 0)?_c('hr'):_vm._e(),(_vm.purpose.allocations && _vm.purpose.allocations.length > 0 && _vm.allocations && _vm.allocations.length > 0)?_c(VDataTable,{attrs:{"items":_vm.allocations,"headers":[
      { value: 'assetClassId', text: 'Asset Class' },
      { value: 'target', text: 'Target' },
      { value: 'actual', text: 'Actual' },
      { value: 'drift', text: 'Drift' },
      { value: 'amount', text: 'Amount' },
      { value: 'sell', text: 'Sell' },
      { value: 'buy', text: 'Buy' },
    ],"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.assetClassId",fn:function({ value }){return [(_vm.assetClasses && _vm.assetClasses[value])?_c('div',[_vm._v(" "+_vm._s(_vm.assetClasses[value].name)+" ")]):_c('div',[_vm._v("Other")])]}},{key:"item.target",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formatPercent(value))+" ")]}},{key:"item.actual",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formatPercent(value))+" ")]}},{key:"item.drift",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formatPercent(value))+" ")]}},{key:"item.amount",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.sell",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.buy",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.price))+" ("+_vm._s(item.priceDate)+") ")]}},{key:"body.append",fn:function({ headers }){return [_c('tr',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_c('td',{attrs:{"colspan":headers.length - 4}}),_c('td',{staticClass:"text-right"},[_vm._v("Total")]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(_vm.formatMoney(_vm.total)))])])]}}],null,false,673536897)}):_vm._e(),_c(VDialog,{attrs:{"max-width":"800px"},model:{value:(_vm.showEditPurposeDialog),callback:function ($$v) {_vm.showEditPurposeDialog=$$v},expression:"showEditPurposeDialog"}},[_c('Card',{attrs:{"title":"Edit Purpose","icon":"mdi-piggy-bank","inDialog":""}},[_c('DynamicForm',{attrs:{"object":_vm.purpose,"fields":[
        { fieldName: 'name', displayName: 'Name', type:String, rules:[_vm.rules.required]},
        { fieldName: 'allocations', displayName: 'Allocations', type:'AssetAllocationList', values: _vm.assetClassesForDropdown, addAction: (o) => _vm.$emit('addAssetClass', o), rules:[_vm.rules.required]},
      ],"deleteMethod":!_vm.holdings || _vm.holdings.length === 0 ? (o) => this.$api.financial_investments.deletePurpose(o) : null,"saveMethod":(o) => _vm.$api.financial_investments.savePurpose(o),"cancelCallback":() => this.showEditPurposeDialog = false,"successCallback":() => { this.showEditPurposeDialog = false; _vm.$emit('saved') }}})],1)],1),_c(VCardActions,[(_vm.holdings && _vm.holdings.length > 0)?_c(VSwitch,{attrs:{"label":"Show All Assets"},model:{value:(_vm.showAllAssets),callback:function ($$v) {_vm.showAllAssets=$$v},expression:"showAllAssets"}}):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"fab":""},on:{"click":function($event){_vm.showEditPurposeDialog = true}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }