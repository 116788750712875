import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import API from './api'
import moment from 'moment'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' // Globally import VTextField
Vue.config.productionTip = false

Vue.prototype.moment = moment;
Vue.prototype.$api = new API(location.protocol === "https:", location.host);

// Declaring the module is required to use this.$api without typescript errors
declare module "vue/types/vue" {
  interface Vue {
    $api: API;
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js')
    .then(function(serviceWorkerRegistration) {
      console.log('Service Worker is registered', serviceWorkerRegistration);
    })
    .catch(function(error) {
      console.error('Service Worker Error', error);
    });
}

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

Vue.mixin({
  methods: {
    formatMoney(amount) {
      if (amount === undefined || amount === null)
        return '';

      const isNegative = amount < 0;
      if (isNegative)
        amount = amount * -1;
      let formattedAmount = (isNegative ? "-" : "") + "$" + new Intl.NumberFormat('en-US').format(amount);
      const dec = formattedAmount.split('.')[1]

      if (!dec || dec.length < 1)
        formattedAmount += ".00"

      if (dec && dec.length === 1)
        formattedAmount += "0"

      return formattedAmount;
    },
    formatPercent(amount) {
      if (amount === undefined || amount === null)
        return '';
      return Math.round(amount * 100) / 100 + '%';
    },
    getEnumArray: function (sourceEnum) {
      const parsableArray = [];
      for (const enumValue in sourceEnum) {
        parsableArray.push({
          text: sourceEnum[enumValue],
          value: enumValue
        });
      }
      return parsableArray;
    },
  },
})
