<template>
  <div>
    <Card title="Newtons" icon="mdi-router-network" wide>
      <EditableTable
        :table-subscription="(handler) => this.$api.newton.subscribeAll(handler)"
        :fields="[
          {fieldName:'id', displayName: 'Id', type: String},
          {fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[]},
          {fieldName:'enabled', displayName: 'Enabled', editable: true, type: Boolean, rules:[]},
          {fieldName:'householdId', displayName: 'Household Id', editable: true, type: String, rules:[]},
          {fieldName:'remoteIP', displayName: 'IP', editable: true, type: String, rules:[]},
          {fieldName:'localIP', displayName: 'Local IP', editable: true, type: String, rules:[]},
          {fieldName:'codeHash', displayName: 'Code Hash', type: String, rules:[]},
          {fieldName:'verificationCode', displayName: 'Verification Code', editable: true, type: String, rules:[]},
          {fieldName:'status', displayName: 'Status', type: 'Enumerator', rules:[]},
          {fieldName:'currentHostname', displayName: 'Hostname', type: String, rules:[]},
          {fieldName:'statusTimestamp', displayName: 'Status Timestamp', type: String, rules:[]},
          ]"
        :save-method="(data) => this.$api.newton.saveNewtonAdmin(data)"
      />
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import EditableTable from '@/components/EditableTable'
export default {
  name: 'AdminNewtons',
  components: { EditableTable, Card },
}
</script>

<style scoped>

</style>
