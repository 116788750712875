import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',{attrs:{"title":"Basic Information","icon":"mdi-currency-usd"}},[_c('EditableObject',{attrs:{"object":_vm.financialModule,"fields":[{fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[_vm.rules.required]}],"save-method":(data) => this.$api.financial.saveFinancialModule(data)}}),_c('br'),_c('ButtonWithConfirmation',{attrs:{"confirmation-text":"Are you sure you want to delete \"" + _vm.financialModule.name + "\"?","confirmation-icon":"mdi-delete","button-text":"Delete","button-color":"error","action":() => _vm.$api.financial.deleteFinancialModule(_vm.financialModule),"success-callback":() => _vm.$router.push('/')}})],1),_c('Card',{attrs:{"title":"Income Sources","icon":"mdi-cash-marker"}},[_c('EditableTable',{attrs:{"hideFilter":"","table-subscription":(handler) => this.$api.financial_income_source.subscribeToIncomeSources(_vm.financialModule.id, handler),"fields":[
        {fieldName:'id', displayName: 'Id', type: String},
        {fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[]},
        {fieldName:'keywordsCSV', displayName: 'Keywords', editable: true, type: String, rules:[]},
        // {fieldName:'friendlyKey', displayName: 'Key', editable: false, type: String, rules:[]},
        // {fieldName:'enabled', displayName: 'Enabled', editable: false, type: Boolean, rules:[]},
        ],"save-method":(data) => this.$api.financial_income_source.saveIncomeSource(data)}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","fab":"","elevation":0},on:{"click":function($event){_vm.showCreateIncomeSourceDialog = true}}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)],1),_c('Permissions',{attrs:{"id":_vm.financialModule.id,"type":"financialmodule","showHeaderDivider":""}}),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.showCreateIncomeSourceDialog),callback:function ($$v) {_vm.showCreateIncomeSourceDialog=$$v},expression:"showCreateIncomeSourceDialog"}},[_c('Card',{attrs:{"title":"Create Income Source","icon":"mdi-plus","inDialog":""}},[_c('DynamicForm',{key:_vm.showCreateIncomeSourceDialog,attrs:{"object":{financialModuleId: _vm.financialModule.id, name: '', keywordsCSV: ''},"fields":[
            {fieldName:'name', displayName: 'Name', placeholderFieldName: 'officialName', type: String, rules:[_vm.rules.required]},
          ],"save-method":(data) => {
            return this.$api.financial_income_source.createIncomeSource(data);
          },"cancel-callback":() => this.showCreateIncomeSourceDialog = false,"success-callback":(o) => {this.showCreateIncomeSourceDialog = false},"focus-index":0}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }