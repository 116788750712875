<template>
  <div>
    <v-expansion-panels v-bind:focusable="panel.length > 0" multiple flat tile inset v-model="panel">
      <v-expansion-panel v-on:click="clickPanel">
        <v-divider v-if="showHeaderDivider"/>
        <v-expansion-panel-header hide-actions class="pa-0" v-slot="{ open }">
          <!-- Viewer   -->
          <div>
            <div v-for="(field, index) in viewFields" v-bind:key="field.fieldName">
              <v-list-item
                v-if="!field.hidden"
                @click="(!open && (field.editable || field.clickable)) && editObject(index)"
                :inactive="open || !(field.editable || field.clickable)"
                :ripple="false"
              >
                <v-list-item-content class="text-uppercase text--secondary">{{field.displayName}}</v-list-item-content>
                <v-list-item-content class="subtitle-1">
                  <span v-if="field.type === 'moment.fromNow'">{{moment(object[field.fieldName]).fromNow()}}</span>
                  <span v-else-if="field.password">({{(object[field.fieldName] || "").length}} characters)</span>
                  <span v-else>{{object[field.fieldName]}}</span>
                </v-list-item-content>
                <v-list-item-icon><v-icon v-if="!open && (field.editable || field.clickable)">mdi-pencil</v-icon></v-list-item-icon>
              </v-list-item>
              <v-divider v-if="index < (viewFields.length - 1) && !field.hidden"/>
            </div>
          </div>
        </v-expansion-panel-header>
        <!-- Editor   -->
        <v-expansion-panel-content>

          <slot
            v-bind:reloadForm="reloadForm"
            v-bind:object="object"
            v-bind:fields="editFields"
            v-bind:saveMethod="saveMethod"
            v-bind:cancelCallback="cancelEditing"
            v-bind:focusIndex="clickedIndex"
            v-bind:saveText="saveText"
            v-bind:successCallback="successDoneEditing"
          >
            <DynamicForm
              :key="reloadForm"
              :object="object"
              :fields="editFields"
              :saveMethod="saveMethod"
              :cancelCallback="cancelEditing"
              :focusIndex="clickedIndex"
              :saveText="saveText"
              :successCallback="successDoneEditing"
            >
            </DynamicForm>
          </slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import DynamicForm from '@/components/DynamicForm'
  export default {
    name: 'EditableObject',

    components: { DynamicForm },

    props: {
      fields: Array,
      object: Object,
      saveMethod: Function,
      saveText: String,
      successCallback: Function,
      cancelCallback: Function,
      showHeaderDivider: Boolean
    },

    data: () => ({
      clickedIndex: 0,
      reloadForm: false,
      panel: []
    }),

    computed: {
      viewFields() {
        return this.fields.filter(field => {
          return !field.hidden;
        });
      },
      editFields() {
        return this.fields.filter(field => {
          return field.editable;
        });
      }
    },

    methods: {
      editObject: function(index) {
        this.clickedIndex = index;
        this.reloadForm = !this.reloadForm;
      },
      successDoneEditing: function() {
        this.reloadForm = !this.reloadForm;
        this.panel = [];
        if (this.successCallback)
          this.successCallback();
      },
      cancelEditing() {
        this.panel = [];
        if (this.cancelCallback)
          this.cancelCallback();
      },
      clickPanel() {
        this.reloadForm = !this.reloadForm;
      }
    }
  }
</script>

<style scoped>
</style>
