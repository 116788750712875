<template>
  <div>
    <Card v-if="'create' === listId" icon="mdi-clipboard-check" title="Create List">
      <DynamicForm
        :key="listId"
        :object="{}"
        :fields="[{fieldName:'name', displayName:'Name', type: String, rules:[rules.required]}]"
        :save-method="(data) => this.$api.list.createList(listHolder.id, data.name)"
        :success-callback="(item) => $router.push('/h/' + household.id + '/list/' + listHolder.id + '/' + item.id)"
        :focus-index="0"
      />
    </Card>
    <div v-else-if="'settings' === listId">
      <Card v-if="listHolder" icon="mdi-clipboard-check-multiple" :title="listHolder.name">
        <EditableObject
          :object="listHolder"
          :fields="[{fieldName:'name', displayName:'Name', type:String, editable: true, rules:[rules.required]}]"
          :saveMethod="(o) => $api.list.saveListHolder(o)"
        >
        </EditableObject>
        <br/>
        <ButtonWithConfirmation
          :confirmation-text='"Are you sure you want to delete \"" + listHolder.name + "\"?"'
          confirmation-icon="mdi-delete"
          button-text="Delete"
          button-color="error"
          :action="() => $api.list.deleteListHolder(listHolder)"
          :success-callback="() => $router.push('/h/' + household.id)"
        />
      </Card>

      <Permissions v-if="listHolder" :id="listHolder.id" type="listholder" showHeaderDivider/>

      <Card
        v-for="list in lists" :key="list.id"
        :icon="'mdi-alpha-' + list.name.substr(0, 1).toLowerCase() + '-box'"
        :title="list.name"
      >
        <EditableObject
          :object="list"
          :fields="[{fieldName:'name', displayName:'Name', type:String, editable: true, rules:[rules.required]}]"
          :saveMethod="(o) => $api.list.saveList(o)"
        >
        </EditableObject>
        <br/>
        <ButtonWithConfirmation
          :confirmation-text='"Are you sure you want to delete \"" + list.name + "\"?"'
          confirmation-icon="mdi-delete"
          button-text="Delete"
          button-color="error"
          :action="() => $api.list.deleteList(list)"
        />
      </Card>
    </div>
    <List
      v-else-if="listHolder && listId" :key="listId"
      :list-id="listId" :list-holder="listHolder" :household="household"
    />
    <card v-else-if="lists && lists.length === 0" :title="'Welcome to your set of lists called ' + listHolder.name + '!'">
      <span class="subtitle-1">
        You can always change this name in
        <v-btn rounded color="primary" :to="'/h/' + this.household.id + '/list/' + listHolder.id + '/settings'"><v-icon left>mdi-cog</v-icon> Settings</v-btn>
        <br/>
        <br/>
        Create your first list by clicking
        <v-btn rounded color="primary" :to="'/h/' + this.household.id + '/list/' + listHolder.id + '/create'"><v-icon left>mdi-plus</v-icon> Create list</v-btn>
      </span>
    </card>
    <div v-else>
      <List
        v-for="list in lists" :key="list.id"
        :list-id="list.id" :list-holder="listHolder" :household="household" readOnly
      />
    </div>
  </div>
</template>

<script>
  import DynamicForm from '@/components/DynamicForm'
  import Card from '@/components/Card'
  import List from '@/hoamodules/list/List'
  import EditableObject from '@/components/EditableObject'
  import ButtonWithConfirmation from '@/components/ButtonWithConfirmation'
  import Permissions from '@/components/Permissions'
  export default {
    name: 'ListHolder',
    components: { Permissions, ButtonWithConfirmation, EditableObject, List, Card, DynamicForm },
    props: ['household', 'listHolderId', 'listId'],

    data: () => ({
      listHolder: null,
      lists: null,
      subscription: null,
      resolved: false,
      rules: {
        required: value => !!value || 'Required',
      }
    }),

    methods: {
      updateBreadcrumbs() {
        const breadcrumbs = [{
          text: this.listHolder.name,
          icon: 'mdi-clipboard-check-multiple',
          to: '/h/' + this.household.id + '/list/' + this.listHolder.id
        }];

        this.$emit('breadcrumbs', breadcrumbs);
      },
      resubscribe(listHolderId) {
        if (this.subscription)
          this.$api.unsubscribe(this.subscription);

        this.resolved = false;
        this.subscription = this.$api.list.subscribeToListHolder(listHolderId, listHolder => {
          const menuItems = [{
            title: listHolder.listHolder.name,
            icon: 'mdi-clipboard-check-multiple',
            to: '/h/' + this.household.id + '/list/' + listHolder.id,
            color: listHolder.listHolder.color,
          }];

          listHolder.lists.forEach(list => {
            menuItems.push({
              title: list.name,
              icon: 'mdi-alpha-' + list.name.substr(0, 1).toLowerCase() + '-box',
              to: '/h/' + this.household.id + '/list/' + listHolder.id + '/' + list.id
            });
          });

          menuItems.push({
            title: 'Create List',
            icon: 'mdi-plus',
            to: '/h/' + this.household.id + '/list/' + listHolder.id + '/create'
          });

          menuItems.push({
            title: 'Settings',
            icon: 'mdi-cog',
            to: '/h/' + this.household.id + '/list/' + listHolder.id + '/settings'
          });

          this.$emit('menuItems', menuItems);
          this.listHolder = listHolder.listHolder;
          this.lists = listHolder.lists;
          this.updateBreadcrumbs();
        }).unsubscribed.then(() => {
          this.listHolder = null;
          this.resolved = true;
        });
      }
    },

    watch: {
      listHolderId(listHolderId) {
        this.resubscribe(listHolderId);
      },
      // eslint-disable-next-line
      listId(listId) {
        this.childBreadcrumbs = [];
        this.updateBreadcrumbs();
      }
    },

    mounted() {
      this.resubscribe(this.listHolderId);
    },

    destroyed() {
      if (this.subscription)
        this.$api.unsubscribe(this.subscription);
    }
  }
</script>

<style scoped>

</style>
