<template>
  <div>
    <Card v-if="newton" title="Petals" icon="mdi-spa" wide>
      <EditableTable
        :table-subscription="(handler) => this.$api.petal.subscribeByParent(newton.id, handler)"
        empty-table-text="No pedals have been provisioned, yet. Connect a petal to the USB port on a Newton device to provision."
        :fields="[
                {fieldName:'connected', displayName: '', editable: false, type: Boolean},
                {fieldName:'macAddress', displayName: 'MAC', type: String},
                {fieldName:'usbConnectedTimestamp', displayName: 'USB Connected', editable: true, readOnly: true, hidden: true, type: 'Timestamp'},
                {fieldName:'authorized', displayName: 'Authorized', editable: true, hidden: true, type: Boolean},
                {fieldName:'provisionedTimestamp', displayName: 'Provisioned', editable: true, readOnly: true, hidden: true, type: 'Timestamp'},
                {fieldName:'autoUpdate', displayName: 'Auto-Update', editable: true, hidden: true, type: Boolean},
                {fieldName:'releaseChannel', displayName: 'Release Channel', editable: true, hidden: true, type: 'Enumerator', values: ['STABLE', 'BETA', 'DEV']},
                {fieldName:'firmwareName', displayName: 'Firmware', type: String},
                {fieldName:'firmwareCommit', displayName: 'Version', type: String},
                {fieldName:'localIP', displayName: 'IP', type: String},
                {fieldName:'wsConnectedTimestamp', displayName: 'Connected', editable: false, hidden: false, type: String},
                // {fieldName:'updating', displayName: 'Updating', editable: false, type: Boolean},
                {fieldName:'methods', displayName: 'Methods', editable: false, hidden: true, type: String},
                {fieldName:'lastResetReason', displayName: 'Reset Reason', editable: false, hidden: false, type: String},
                {fieldName:'methods', displayName: 'Methods', type: String},
                ]"
        :save-method="(data) => this.$api.petal.savePetal(data)"
      >
        <template v-slot:item.authorized="{ item }">
          <v-simple-checkbox
            v-model="item.authorized"
            disabled
          />
        </template>
        <template v-slot:item.connected="{ item }">
          <v-icon v-if="item.updating">mdi-download-circle</v-icon>
          <v-icon v-else-if="item.connected">mdi-checkbox-marked-circle</v-icon>
          <v-icon v-else>mdi-close-circle</v-icon>
        </template>
        <template v-slot:item.wsConnectedTimestamp="{ item }">
          <div v-if="item.wsConnectedTimestamp" >
            {{moment(item.wsConnectedTimestamp).from(moment.utc(now))}}
          </div>
        </template>
        <template v-slot:item.methods="{ item }">
          <div v-if="item.methods" >
            <v-btn icon @click="openPetalMethodDialog(item);">
              <v-icon>mdi-application-variable-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </EditableTable>
    </Card>

    <v-dialog v-model="petalMethodDialog" max-width="1023">
      <Card title="Petal RPC" icon="mdi-application-variable-outline" inDialog v-if="petalMethodDialog">
        <DynamicForm
          :key="petalMethodDialogObject.method"
          :object="petalMethodDialogObject"
          :fields="petalMethodDialogFields"
          save-text="Invoke"
          :save-method="(data) => this.$api.petal.invokePetalMethod(petalMethodDialogPetal.id, data.method, data.booleanParameter, data.longParameter, data.floatParameter, data.stringParameter)"
          :cancel-callback="() => this.petalMethodDialog = false"
          :success-callback="(o) => this.petalMethodDialog = false"
          :focus-index="0"
          v-on:methodchange="generatePetalMethodDialogFields($event.method)"
        ></DynamicForm>
      </Card>
    </v-dialog>

    <Card v-if="newton" title="Basic Information" icon="mdi-router-network" wide>
      <!--    {{newton}}-->
      <EditableObject
        :object="newton"
        :fields="[
        {fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[rules.required]},
        {fieldName:'status', displayName: 'Status'},
        {fieldName:'statusTimestamp', displayName: 'Status Timestamp', type: 'moment.fromNow'},
        {fieldName:'remoteIP', displayName: 'Remote IP'},
        {fieldName:'localIP', displayName: 'Local IP'},
        ]"
        :save-method="saveNewton"
      />
      <br/>
      <ButtonWithConfirmation
        :confirmation-text='"Are you sure you want to forget \"" + newton.name + "\"?"'
        confirmation-icon="mdi-delete"
        button-text="Forget Device"
        button-color="error"
        :action="() => $api.newton.removeHouseholdFromNewton(newton.id)"
        :success-callback="() => $router.push('/')"
      />
    </Card>

    <Card v-if="newton" title="Petal Wi-Fi" icon="mdi-wifi-cog" wide>
      <!--    {{newton}}-->
      <EditableObject
        :object="newton"
        :fields="[
        {fieldName:'petalSSID', displayName: 'SSID', editable: true, type: String, rules:[]},
        {fieldName:'petalPassphrase', displayName: 'Passphrase', password: true, editable: true, type: String, rules:[]},
        ]"
        :save-method="saveNewton"
      />
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import EditableObject from '@/components/EditableObject'
import ButtonWithConfirmation from '@/components/ButtonWithConfirmation'
import EditableTable from '@/components/EditableTable'
import DynamicForm from '@/components/DynamicForm'

export default {
  name: 'HouseholdNewton',
  components: { DynamicForm, EditableTable, ButtonWithConfirmation, EditableObject, Card },
  props: ['newtonId'],
  data: () => ({
    nowUpdaterInterval: null,
    now: Date.now(),
    newton: null,
    subscription: null,
    rules: {
      required: value => !!value || 'Required',
    },
    petalMethodDialog: false,
    petalMethodDialogPetal: null,
    petalMethodDialogObject: {},
    petalMethodDialogFields: []
  }),

  methods: {
    resubscribe(newtonId) {
      if (this.subscription)
        this.$api.unsubscribe(this.subscription);

      this.subscription = this.$api.newton.subscribe(newtonId, newton => {
        this.newton = newton;
      }).unsubscribed.then(() => {
        this.newton = null;
      });
    },

    saveNewton(newton) {
      return this.$api.newton.saveNewton(newton);
    },

    openPetalMethodDialog(petal) {
      this.petalMethodDialogPetal = petal;
      this.petalMethodDialogPetal.methodNames = [];
      this.petalMethodDialogPetal.methods.forEach(method => {
        this.petalMethodDialogPetal.methodNames.push(method.methodName);
      })
      this.petalMethodDialogObject = {};
      this.generatePetalMethodDialogFields(null);
      this.petalMethodDialog = true;
    },
    generatePetalMethodDialogFields(methodName) {
      const fields = [
        { fieldName: 'method', displayName: 'Method', type: 'Enumerator', values: this.petalMethodDialogPetal.methodNames, rules: [this.rules.required] },
      ];
      let currentMethod = null;
      this.petalMethodDialogPetal.methods.forEach(method => {
        if (method.methodName === methodName)
          currentMethod = method;
      });
      if (currentMethod != null && currentMethod.parameterType === "BOOLEAN")
        fields.push({ fieldName: 'booleanParameter', displayName: '', type: Boolean, rules: [] });
      if (currentMethod != null && currentMethod.parameterType === "LONG")
        fields.push({ fieldName: 'longParameter', displayName: 'Long', type: Number, rules: [this.rules.required] });
      if (currentMethod != null && currentMethod.parameterType === "FLOAT")
        fields.push({ fieldName: 'floatParameter', displayName: 'Float', type: Number, rules: [this.rules.required] });
      if (currentMethod != null && currentMethod.parameterType === "STRING")
        fields.push({ fieldName: 'stringParameter', displayName: 'String', type: String, rules: [this.rules.required] });
      this.petalMethodDialogFields = fields;
    }
  },

  computed: {
    modulesUpdateCounter() {
      return this.$store.state.modulesUpdate;
    }
  },

  watch: {
    newtonId(newtonId) {
      this.resubscribe(newtonId);
    },
    modulesUpdateCounter(modulesUpdateCounter) {
      this.$emit('resetMenu');
    }
  },

  mounted() {
    this.$emit('breadcrumbs', []);
    this.$emit('resetMenu');
    this.resubscribe(this.newtonId);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    this.nowUpdaterInterval = setInterval(function () {
      me.now = Date.now() + 1000;
    }, 1000)
  },

  destroyed() {
    if (this.subscription)
      this.$api.unsubscribe(this.subscription);
    clearInterval(this.nowUpdaterInterval);
  }
}
</script>

<style scoped>

</style>
