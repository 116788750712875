<template>
    <Card title="Not Found" icon="mdi-alert">
      <div>Sorry, we couldn't find what you were looking for.</div>
    </Card>
</template>

<script>
  import Card from '@/components/Card'
  export default {
    name: 'NotFound',
    components: { Card },
    mounted() {
      this.$emit('menuItems', []);
    }
  }
</script>

<style scoped>

</style>
