<template>
  <div>
    <Card title="Net Worth" icon="mdi-egg">
      {{formatMoney(accounts.reduce((a, b) => a + (b.balanceWithPending || 0), 0))}}
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
export default {
  name: 'NetWorth',
  components: { Card },
  props: ['household', 'financialModule', 'accounts'],
  data: () => ({

  }),
  methods: {

  },
  mounted() {
    this.$emit('breadcrumbs', [
      {
        text: 'Net Worth',
        icon: 'mdi-egg',
        to: '/h/' + this.household.id + '/f/' + this.financialModule.id + '/networth'
      }
    ]);
  }
}
</script>

<style scoped>

</style>
