<template>
  <v-form @submit="attemptSignin">
    <v-card-text>
      <v-text-field autofocus v-model="username" label="Username" prepend-icon="mdi-account" :readonly="isLoggingIn" :error-messages="errorMessage ? ' ' : ''"/>
      <v-text-field v-model="password" type="password" prepend-icon="mdi-lock" label="Password" :readonly="isLoggingIn" :error-messages="errorMessage"/>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" to="register">Register</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" type="submit" @click="attemptSignin" :disabled="!isLoginFormValid" :loading="isLoggingIn">Sign in</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  export default {
    name: 'Login',

    data: () => ({
      isLoggingIn: false,
      username: "",
      password: "",
      errorMessage: ""
    }),

    computed: {
      isLoginFormValid() {
        return this.username && this.username.length > 0 && this.password && this.password.length > 0 && !this.errorMessage;
      }
    },

    methods: {
      attemptSignin: function (e) {
        e.preventDefault();

        if (!this.isLoginFormValid)
          return;

        this.isLoggingIn = true;
        this.errorMessage = "";
        // this.$store.commit('logIn');

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vue = this;
        this.$api.signIn(this.username, this.password)
          .then(() => this.$api.connectWebsocket())
          .catch(() => {
            vue.errorMessage = "Invalid username or password";
            vue.isLoggingIn = false;
          });
      }
    },

    watch: {
      username: function() {
        this.errorMessage = "";
      },
      password: function() {
        this.errorMessage = "";
      }
    }
  }
</script>

<style scoped>

</style>
