
  import Vue from 'vue'
  import Login from './components/Login.vue'
  import DynamicForm from "@/components/DynamicForm.vue";
  import Card from "@/components/Card.vue";

  export default Vue.extend({
    name: 'App',

    components: {
      Card,
      DynamicForm,
      Login
    },

    data: () => {
      return {
        availableModules: [],
        showErrorDialog: false,
        errorMessage: "",
        isLoggedOut: false,
        showCreateHouseholdDialog: false,
        barBreadcrumbs: [],
        isMenuMini: false,
        lockMenuLarge: false,
        menuItems: undefined,
        windowWidth: window.innerWidth,
        smallScreen: window.innerWidth < 600,
        connectErrorCount: 0,
        pendingApiEvents: 0,
      };
    },

    methods: {
      onResize() {
        if (this.windowWidth !== window.innerWidth) {
          // console.dir("window.innerWidth: " + window.innerWidth);
          this.windowWidth = window.innerWidth;
          this.smallScreen = this.windowWidth < 600;
          this.lockMenuLarge = this.windowWidth >= 1600;
          this.$nextTick(() => {
            this.lockMenuLarge = this.windowWidth >= 1600;
          });
        }
      },
      // eslint-disable-next-line
      setBreadcrumbs(childBreadcrumbs: any) {
        this.barBreadcrumbs = childBreadcrumbs;
      },
      // eslint-disable-next-line
      setMenuItems(menuItems: any) {
        // eslint-disable-next-line
        const emptyArray: any = [];
        this.menuItems = menuItems && menuItems.length > 0 ? emptyArray : undefined;
        if (this.windowWidth < 1600)
          this.lockMenuLarge = false;
        this.$nextTick(() => {
          this.menuItems = menuItems && menuItems.length > 0 ? menuItems : undefined;
          if (this.windowWidth < 1600)
            this.lockMenuLarge = false;
        });
      },
      logout: function() {
        this.$api.logout();
        this.$router.push("/").catch(err => err);
      },
      showError(message: string) {
        this.showErrorDialog = false;
        this.errorMessage = message;
        this.showErrorDialog = true;
      },
      hideLoadingBar() {
        const loadingBar = document.getElementById('initialLoadingBar');
        if (loadingBar)
          loadingBar.style.display = 'none';
      },
      showLoadingBar() {
        const loadingBar = document.getElementById('initialLoadingBar');
        if (loadingBar)
          loadingBar.style.display = 'flex';
      },
      showHideLoadingBar(count: number) {
        this.pendingApiEvents = count;
        if (count > 0)
          setTimeout(this.showHideLoadingBarWithoutTimer, 100);
        else
          this.hideLoadingBar();
      },
      showHideLoadingBarWithoutTimer() {
        if (this.pendingApiEvents > 0)
          this.showLoadingBar();
        else
          this.hideLoadingBar();
      },
      wsConnected() {
        this.$api.user.subscribe('myentityid', user => {
          if (this.isLoggedOut)
            this.isLoggedOut = false;
          this.$store.commit('updateUser', user);
        });

        this.$api.module.subscribe("myentityid", userModules => {
          this.$store.commit('updateUserModules', userModules);
        });

        this.connectErrorCount = 0;
        this.hideLoadingBar();
      },
      wsDisconnected(reason: string | undefined) {
        this.$store.commit('updateUser', null);
        if (reason && reason.includes("session") && reason.includes("ended")) {
          this.isLoggedOut = true;
        } else {
          this.showLoadingBar();
          setTimeout(this.checkSessionAndConnect, 100);
        }
      },
      checkSessionAndConnect() {
        this.$api.user.isLoggedIn()
          .then((isLoggedIn) => {
            if (isLoggedIn) {
              this.$api.connectWebsocket();
            } else {
              this.isLoggedOut = true;
              this.hideLoadingBar();
            }
          })
          .catch(() => {
            this.connectErrorCount++;
            setTimeout(this.checkSessionAndConnect, 1000);
          });
      }
    },

    mounted: function () {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });

      this.$api.registerErrorHandler(this.showError);
      this.$api.registerOnConnectHandler(this.wsConnected);
      this.$api.registerOnCloseHandler(this.wsDisconnected);
      this.$api.registerPendingApiEventCountHandler(this.showHideLoadingBar);

      this.checkSessionAndConnect();
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
  })
