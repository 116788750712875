<template>
  <Card :title="'Welcome to your ' + household.name + ' dashboard!'">
    <span class="subtitle-1">
      Add an app to your household by clicking
      <v-btn rounded color="primary" :to="'/h/' + this.household.id + '/add'"><v-icon left>mdi-plus</v-icon> Add App</v-btn>
      <br/>
      Be sure to check back every once in a while as we are always adding more apps!
    </span>
  </Card>
</template>

<script>
  import Card from '@/components/Card'
  export default {
    name: 'HouseholdDashboard',
    components: { Card },
    props: ['household'],

    mounted() {
      this.$emit('breadcrumbs', []);
      this.$emit('resetMenu');
    }
  }
</script>

<style scoped>

</style>
