<template>
  <li :style="(show ? '' : 'display: none;') + ' list-style-type: none;'">
    <v-icon v-if="module.icon" small>{{module.icon}}</v-icon>
    {{module.name}}
    <ul style="list-style-type: none;">
      <span v-for="actionItem in actionItems" :key="actionItem.id">
        <li v-if="!actionItem.deleted">
          <router-link :to="actionItem.path">{{actionItem.name}}</router-link>
        </li>
      </span>
      <span v-for="subModule in module.subModules" :key="subModule.id">
        <ActionItems :module="subModule" v-on:show="handleSubModuleShowEvent(subModule.id, $event)"/>
      </span>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'ActionItems',
  props: ['module'],

  data: () => ({
    show: false,
    actionItems: null,
    hasActionItems: false,
    actionItemsSubscription: null,
    subModulesHaveActionItems: {}
  }),

  methods: {
    handleSubModuleShowEvent(id, event) {
      this.$set(this.subModulesHaveActionItems, id, event);
      this.emitShow();
    },
    emitShow() {
      if (this.actionItems || this.subModulesHaveActionItems) {
        let show = this.hasActionItems;
        Object.values(this.subModulesHaveActionItems).forEach(subShow => { show = show || subShow });
        this.show = show;
        this.$emit('show', show);
      }
    },
    unsubscribeAll() {
      if (this.actionItemsSubscription)
        this.$api.unsubscribe(this.actionItemsSubscription);
      this.actionItemsSubscription = null;
    },
    loadData() {
      this.unsubscribeAll();
      if (this.module.actionItemsId) {
        this.actionItems = [];
        this.actionItemsSubscription = this.$api.action_item.subscribeToActionItems(this.module.actionItemsId, newItem => {
          this.$api.arrayHandler(this.actionItems, true)(newItem);

          let hasActionItems = false;
          this.actionItems.forEach(item => {
            if (!item.deleted)
              hasActionItems = true;
          });

          this.hasActionItems = hasActionItems;
          this.emitShow();
        });
      }
    }
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.unsubscribeAll();
  }
}
</script>

<style scoped>

</style>
