import Vue from 'vue'
import Vuex from 'vuex'
import { UIModule, UIUserFull } from "@/api";

Vue.use(Vuex)

class State {
  public user: UIUserFull | null = null;
  public modules: UIModule | null = null;
  public modulesUpdate = 0;
}

export default new Vuex.Store({
  state: new State(),
  mutations: {
    updateUser (state, newUser: UIUserFull) {
      state.user = newUser;
    },
    updateUserModules (state, newUserModules: UIModule) {
      state.modules = newUserModules;
      state.modulesUpdate = state.modulesUpdate + 1;
    },
  },
  actions: {
  },
  modules: {
  }
})
