<template>
  <v-card
    outlined
    :max-width="wide ? 3000 : 1023"
    :class="'mx-auto my-' +  (inDialog ? '0' : '4')"
    :style="'' + (inDialog ? 'background:#121212;' : '')"
    :to="to"
    :color="errorTheme ? '#FF0000' : undefined"
  >
    <v-card-title>
      <v-icon v-if="icon" class="mr-3">{{icon}}</v-icon>
      <span :style="'flex: 1; word-break: break-word;'">{{title}}</span>
      <span v-if="titleRight" :style="'padding-left: 16px;' + (titleRightColor ? 'color: ' + titleRightColor + ';' : '')" >{{titleRight}}</span>
      <v-btn v-if="actions && actions.length == 1"
             @click="actions[0].click"
             :color="actions[0].color"
             :fab="!!actions[0].color"
             :small="!!actions[0].color"
             :icon="!actions[0].color"
      >
        <v-icon>{{ actions[0].icon }}</v-icon>
      </v-btn>
      <v-menu v-else-if="actions && actions.length > 1" offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list style="background:#121212;" >
          <v-list-item v-for="(item, index) in actions" :key="index" @click="item.click">
            <v-list-item-avatar><v-icon>{{item.icon}}</v-icon></v-list-item-avatar>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-else-if="launchTo" icon :to="launchTo">
        <v-icon>mdi-launch</v-icon>
      </v-btn>
      <v-btn v-else-if="collapsible" icon @click="expanded = !expanded">
        <v-icon v-if="expanded">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon>
      </v-btn>

    </v-card-title>
    <v-card-text v-if="expanded || !collapsible">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: String,
    icon: String,
    inDialog: Boolean,
    actions: Array,
    to: String,
    launchTo: String,
    wide: Boolean,
    titleRight: String,
    titleRightColor: String,
    errorTheme: Boolean,
    collapsible: Boolean,
  },
  data: () => ({
    expanded: false,
  })
}
</script>

<style scoped>

</style>
