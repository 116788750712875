<template>
  <Card v-if="list"
        :icon="readOnly ? 'mdi-alpha-' + list.name.substr(0, 1).toLowerCase() + '-box' : 'mdi-clipboard-check'"
        :title="list.name"
        :actions="readOnly ? undefined : [
          {text:(showButtons ? 'Hide' : 'Show') + ' Buttons', click:toggleButtons, icon:'mdi-gesture-tap-button'},
          {text:(showHelp ? 'Hide' : 'Show') + ' Shortcuts', click:toggleHelp, icon:'mdi-keyboard'},
          {text:'Delete All Completed', click:wipe, icon:'mdi-delete-sweep'},
          {text:'Send List', click:sendList, icon:'mdi-send'},
        ]"
        v-bind:class="{'card-height-for-buttons':showHelp}"
        :launch-to="readOnly ? '/h/' + household.id + '/list/' + listHolder.id + '/' + list.id : null"

  >
    <div v-if="showHelp" style="position: absolute; top: 52px; right: 16px;">
      ↑ - Up<br/>
      ↓ - Down<br/>
      ⌘↑ - Move Up<br/>
      ⌘↓ - Move Down<br/>
      e / F2 - Edit<br/>
      Spacebar - Complete/open<br/>
      Enter - Save/New task below<br/>
      Escape - Cancel<br/>
      Shift Enter - New task above<br/>
    </div>
    <v-text-field
      v-if="newItemAbove"
      outlined autofocus dense hide-details
      v-model="newItemAbove.text"
      :disabled="newItemAbove.saving"
      :append-icon="showButtons ? 'mdi-close-circle' : ''"
      @click:append="clearItemAbove"
      :append-outer-icon="showButtons ? 'mdi-content-save' : ''"
      @click:append-outer="saveItemAbove"
      :spellcheck="true"
    ></v-text-field>
    <!--    https://github.com/SortableJS/Vue.Draggable-->
    <draggable v-model="listItems" @end="savePositions" :disabled="newItemAbove || editItem || newItemBelow || readOnly">
      <div v-for="(item, index) in listItems" :key="index">
        <div v-if="editItem && editItem.id === item.id" class="py-1">
          <v-text-field
            outlined autofocus dense hide-details
            v-model="editItem.text"
            :disabled="editItem.saving"
            :append-icon="showButtons ? 'mdi-close-circle' : ''"
            @click:append="clearEditItem"
            :append-outer-icon="showButtons ? 'mdi-content-save' : ''"
            @click:append-outer="saveEditItem"
            :spellcheck="true"
          ></v-text-field>
        </div>

        <div v-else-if="item"
             :class="'px-3 py-1 subtitle-1' +
      (selectedItemId == item.id ? ' primary' : '')
       + (item.closedTimestamp ? ' closedItem' : '')
       "
             @mousedown="!readOnly && clickItem(item.id)"
             style="border-radius: 4px; height:40px; align-items: center; display: flex;"
        >

          <span v-if="showButtons" @click="openCloseItem(item)" class="mr-2" style="cursor: pointer;">
            <v-icon v-if="item.closedTimestamp">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </span>
          <span :style="item.closedTimestamp ? 'text-decoration: line-through;' : '' ">
            {{item.text}}
          </span>
          <v-spacer />
          <span v-if="showButtons" @click="editThisItem(item)" style="cursor: pointer;">
            <v-icon>mdi-pencil</v-icon>
          </span>
        </div>

        <v-text-field
          v-if="newItemBelow && ((item.position + 1) === newItemBelow.position) "
          outlined autofocus dense hide-details
          v-model="newItemBelow.text"
          :disabled="newItemBelow.saving"
          :append-icon="showButtons ? 'mdi-close-circle' : ''"
          @click:append="clearItemBelow"
          :append-outer-icon="showButtons ? 'mdi-content-save' : ''"
          @click:append-outer="saveItemBelow"
          :spellcheck="true"
        ></v-text-field>
      </div>
    </draggable>

    <!--    {{listItems}}-->
    <!--    newItemBelow: {{newItemBelow}} <br/>-->
    <!--    newItemAbove: {{newItemAbove}} <br/>-->

    <!--    <v-btn color="primary" absolute bottom right fab @click="addItemAtEnd">-->
    <v-card-actions v-if="showButtons">
      <v-spacer/>
      <v-btn color="primary" fab :elevation="0" @click="addItemAtEnd">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>
  </Card>
</template>

<script>
  import Card from '@/components/Card'
  import draggable from 'vuedraggable'

  export default {
    name: 'List',
    components: { Card, draggable },
    props: {
      household: Object,
      listHolder: Object,
      listId: String,
      readOnly: Boolean
    },

    data: () => ({
      list: null,
      subscription: null,
      resolved: false,
      selectedItemId: null,
      editItem: null,
      newItemBelow: null,
      newItemAbove: null,
      listItems: [],
      showButtons: window.innerWidth < 600,
      showHelp: false
    }),

    methods: {
      savePositions() {
        // Save!
        const dirtyItems = [];
        this.listItems.forEach((item, index) => {
          if (item.position !== index)
            item.position = index;
          dirtyItems.push(item);
        });
        this.$api.list.saveOrder(this.list.id, dirtyItems);
      },
      clickItem(itemId) {
        if (!(this.newItemAbove || this.editItem || this.newItemBelow))
          this.selectedItemId = itemId;
      },
      wipe() {
        this.$api.list.wipeList(this.list.id)
          .then(() => {
            this.selectedItemId = null;
          })
      },
      sendList() {
        this.$api.list.sendList(this.list.id)
          .then(() => {
            this.selectedItemId = null;
          })
      },
      toggleHelp() {
        this.showHelp = !this.showHelp;
      },
      toggleButtons() {
        this.showButtons = !this.showButtons;
        if (this.showButtons)
          this.showHelp = false;
      },
      openCloseItem(item) {
        if (item.closedTimestamp)
          this.$api.list.openListItem(item);
        else
          this.$api.list.closeListItem(item);
      },
      editThisItem(item) {
        this.editItem = Object.assign({}, item);
        this.editItem.saving = false;
        this.selectedItemId = null;
      },
      clearEditItem() {
        this.selectedItemId = this.editItem.id;
        this.editItem = null;
      },
      saveEditItem() {
        this.editItem.saving = true;
        this.$api.list.saveListItem(this.editItem)
          .then(() => {
            this.selectedItemId = this.editItem.id;
            this.editItem = null;
          })
          .finally(() => {
            if (this.editItem)
              this.editItem.saving = false;
          });
      },
      clearItemAbove() {
        if (this.listItems.length > 0)
          this.selectedItemId = this.listItems[0].id;
        this.newItemAbove = null;
      },
      saveItemAbove(postCallFunction) {
        this.newItemAbove.saving = true;
        this.$api.list.createListItem(this.list.id, this.newItemAbove.text, this.newItemAbove.position)
          .then((newListItem) => {
            this.selectedItemId = newListItem.id;
            this.newItemAbove = null;
          })
          .finally(() => {
            if (this.newItemAbove)
              this.newItemAbove.saving = false;

            if (postCallFunction)
              postCallFunction();
          });
      },
      clearItemBelow() {
        if (this.listItems.length > 0)
          this.selectedItemId = this.listItems[this.newItemBelow.position - 1].id;
        this.newItemBelow = null;
      },
      saveItemBelow(postCallFunction) {
        this.newItemBelow.saving = true;
        this.$api.list.createListItem(this.list.id, this.newItemBelow.text, this.newItemBelow.position)
          .then((newListItem) => {
            this.selectedItemId = newListItem.id;
            this.newItemBelow = null;
          })
          .finally(() => {
            if (this.newItemBelow)
              this.newItemBelow.saving = false;

            if (postCallFunction)
              postCallFunction();
          });
      },
      addItemAtEnd() {
        if (this.newItemAbove) {
          this.saveItemAbove(this.addItemAtEndWork);
        } else if (this.newItemBelow) {
          this.saveItemBelow(this.addItemAtEndWork);
        } else {
          this.addItemAtEndWork();
        }
      },
      addItemAtEndWork() {
        this.selectedItemId = null;
        if (this.listItems.length > 0) {
          this.newItemBelow = {
            position: this.listItems.length,
            text: '',
            saving: false
          };
        } else {
          this.newItemAbove = {
            position: 0,
            text: '',
            saving: false
          }
        }
      },
      onKeyDown(event) {
        if (this.readOnly)
          return true;
        // console.dir('onKeyDown')
        // console.dir(event);

        let selectedItemIndex = null;
        this.listItems.forEach((item, index) => {
          if (item.id === this.selectedItemId)
            selectedItemIndex = index;
        });

        // Down
        if (event.keyCode === 40) {
          if (this.editItem || this.newItemBelow || this.newItemAbove)
            return;

          if (event.metaKey) {
            // Move the item down
            if (this.selectedItemId === null)
              return;

            if (selectedItemIndex >= 0 && selectedItemIndex < this.listItems.length - 1) {
              this.listItems[selectedItemIndex].position = this.listItems[selectedItemIndex].position + 1;
              this.listItems[selectedItemIndex + 1].position = this.listItems[selectedItemIndex + 1].position - 1;
              this.listItems = this.listItems.sort((a, b) => a.position - b.position);
              const dirtyItems = [];
              dirtyItems.push(this.listItems[selectedItemIndex]);
              dirtyItems.push(this.listItems[selectedItemIndex + 1]);
              this.$api.list.saveOrder(this.list.id, dirtyItems);
            }
          } else {
            // Move the cursor down
            if (this.selectedItemId === null) {
              if (this.listItems.length > 0)
                this.selectedItemId = this.listItems[this.listItems.length - 1].id;
            } else if (selectedItemIndex < this.listItems.length - 1)
              this.selectedItemId = this.listItems[selectedItemIndex + 1].id;
          }
        }

        // Up
        if (event.keyCode === 38) {
          if (this.editItem || this.newItemBelow || this.newItemAbove)
            return;

          if (event.metaKey) {
            // Move the item up
            if (this.selectedItemId === null)
              return;

            if (selectedItemIndex > 0 && selectedItemIndex <= this.listItems.length - 1) {
              this.listItems[selectedItemIndex].position = this.listItems[selectedItemIndex].position - 1;
              this.listItems[selectedItemIndex - 1].position = this.listItems[selectedItemIndex - 1].position + 1;
              this.listItems = this.listItems.sort((a, b) => a.position - b.position);

              const dirtyItems = [];
              dirtyItems.push(this.listItems[selectedItemIndex]);
              dirtyItems.push(this.listItems[selectedItemIndex - 1]);
              this.$api.list.saveOrder(this.list.id, dirtyItems);
            }
          } else {
            // Move the cursor up
            if (this.selectedItemId === null) {
              if (this.listItems.length > 0)
                this.selectedItemId = this.listItems[0].id;
            } else if (selectedItemIndex > 0)
              this.selectedItemId = this.listItems[selectedItemIndex - 1].id;
          }
        }

        // Space
        if (event.keyCode === 32) {
          // Send the space to the field
          if (this.editItem)
            return true;

          if (selectedItemIndex === null)
            return;

          if (this.listItems[selectedItemIndex].closedTimestamp)
            this.$api.list.openListItem(this.listItems[selectedItemIndex]);
          else
            this.$api.list.closeListItem(this.listItems[selectedItemIndex]);

          // this.listItems[selectedItemIndex].closed = !this.listItems[selectedItemIndex].closed;
        }

        // e or F2
        if (event.keyCode === 69 || event.keyCode === 113) {
          // Send the space to the field
          if (this.editItem)
            return true;

          if (selectedItemIndex === null)
            return;

          this.editItem = Object.assign({}, this.listItems[selectedItemIndex]);
          this.editItem.saving = false;
          this.selectedItemId = null;
          return false;
        }

        // Esc
        if (event.keyCode === 27) {
          this.selectedItemId = null;
          if (this.editItem) {
            this.selectedItemId = this.editItem.id;
            this.editItem = null;
          }

          if (this.newItemBelow) {
            this.clearItemBelow();
          }
          if (this.newItemAbove) {
            this.clearItemAbove();
          }
        }

        // Enter
        if (event.keyCode === 13) {
          if (this.editItem) {
            this.saveEditItem();
          } else if (this.newItemAbove) {
            this.saveItemAbove();
          } else if (this.newItemBelow) {
            this.saveItemBelow();
          } else if (this.selectedItemId) {
            if (event.shiftKey) {
              if (selectedItemIndex === 0) {
                this.newItemAbove = {
                  position: 0,
                  text: '',
                  saving: false
                };
              } else {
                this.newItemBelow = {
                  position: selectedItemIndex,
                  text: '',
                  saving: false
                };
              }
            } else {
              this.newItemBelow = {
                position: selectedItemIndex + 1,
                text: '',
                saving: false
              };
            }
            this.selectedItemId = null;
          } else {
            if (this.listItems.length > 0 && !event.shiftKey) {
              this.newItemBelow = {
                position: this.listItems.length,
                text: '',
                saving: false
              };
            } else {
              this.newItemAbove = {
                position: 0,
                text: '',
                saving: false
              }
            }
          }
        }
      },
      resubscribe(listId) {
        if (this.subscription)
          this.$api.unsubscribe(this.subscription);

        this.resolved = false;
        this.subscription = this.$api.list.subscribeToList(listId, list => {
          this.list = list.list;
          this.listItems = list.listItems;
          this.listItems = this.listItems.sort((a, b) => a.position - b.position);
        }).unsubscribed.then(() => {
          this.list = null;
          this.listItems = [];
          this.resolved = true;
        });
      }
    },

    watch: {
      listId(listId) {
        this.resubscribe(listId);
      }
    },

    mounted() {
      this.resubscribe(this.listId);
      document.onkeydown = this.onKeyDown;
    },

    destroyed() {
      if (this.subscription)
        this.$api.unsubscribe(this.subscription);
      document.onkeydown = null;
    }
  }
</script>

<style scoped>
  .closedItem {
    opacity: 0.33;
  }
  .v-text-field__details {
    display: none;
  }
  .card-height-for-buttons {
    min-height: 300px;
  }
</style>
