<template>
  <div>
    <Card title="Basic Information" icon="mdi-currency-usd">
      <EditableObject
        :object="financialModule"
        :fields="[{fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[rules.required]}]"
        :save-method="(data) => this.$api.financial.saveFinancialModule(data)"
      />
      <br/>
      <ButtonWithConfirmation
        :confirmation-text='"Are you sure you want to delete \"" + financialModule.name + "\"?"'
        confirmation-icon="mdi-delete"
        button-text="Delete"
        button-color="error"
        :action="() => $api.financial.deleteFinancialModule(financialModule)"
        :success-callback="() => $router.push('/')"
      />
    </Card>

    <Card title="Income Sources" icon="mdi-cash-marker">
      <EditableTable
        hideFilter
        :table-subscription="(handler) => this.$api.financial_income_source.subscribeToIncomeSources(financialModule.id, handler)"
        :fields="[
          {fieldName:'id', displayName: 'Id', type: String},
          {fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[]},
          {fieldName:'keywordsCSV', displayName: 'Keywords', editable: true, type: String, rules:[]},
          // {fieldName:'friendlyKey', displayName: 'Key', editable: false, type: String, rules:[]},
          // {fieldName:'enabled', displayName: 'Enabled', editable: false, type: Boolean, rules:[]},
          ]"
        :save-method="(data) => this.$api.financial_income_source.saveIncomeSource(data)"
      />
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" fab :elevation="0" @click="showCreateIncomeSourceDialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </Card>
    <Permissions :id="financialModule.id" type="financialmodule" showHeaderDivider/>

    <v-dialog v-model="showCreateIncomeSourceDialog" max-width="500px">
      <Card title="Create Income Source" icon="mdi-plus" inDialog>
        <DynamicForm
          :key="showCreateIncomeSourceDialog"
          :object="{financialModuleId: financialModule.id, name: '', keywordsCSV: ''}"
          :fields="[
              {fieldName:'name', displayName: 'Name', placeholderFieldName: 'officialName', type: String, rules:[rules.required]},
            ]"
          :save-method="(data) => {
              return this.$api.financial_income_source.createIncomeSource(data);
            }"
          :cancel-callback="() => this.showCreateIncomeSourceDialog = false"
          :success-callback="(o) => {this.showCreateIncomeSourceDialog = false}"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>

  </div>
</template>

<script>
  import EditableObject from '@/components/EditableObject'
  import Card from '@/components/Card'
  import ButtonWithConfirmation from '@/components/ButtonWithConfirmation'
  import Permissions from '@/components/Permissions'
  import EditableTable from '@/components/EditableTable.vue'
  import DynamicForm from '@/components/DynamicForm.vue'
  export default {
    name: 'Settings',
    components: { DynamicForm, EditableTable, Permissions, ButtonWithConfirmation, Card, EditableObject },
    props: ['household', 'financialModule', 'incomeSources'],

    data: () => ({
      showCreateIncomeSourceDialog: false,
      rules: {
        required: value => !!value || 'Required',
      }
    }),

    mounted() {
      this.$emit('breadcrumbs', []);
      this.$emit('resetMenu');
    },
  }
</script>

<style scoped>

</style>
