<template>
  <div>
    <Card v-if="$store.state.modules && $store.state.modules.subModules && $store.state.modules.subModules.length <= 0" :title="'Welcome ' + $store.state.user.name + '!'" icon="mdi-emoticon-excited">
      You can create your first household and adjust your user settings under 'Account'.
      <br/>
      <br/>
      <v-btn rounded color="primary" :to="'/account'"><v-icon left>mdi-account-cog</v-icon> Account</v-btn>

    </Card>
    <Card v-if="$store.state.modules && $store.state.modules.subModules" title="Action Items" icon="mdi-format-list-checkbox" :style="(anyShownActionItems ? '' : 'display: none;')">
      <ActionItems
        v-for="module in $store.state.modules.subModules"
        :key="module.id"
        :module="module"
        v-on:show="$set(showActionItems, module.id, $event)"
      />
    </Card>
    <div v-if="$store.state.modules">
      <Card v-for="household in $store.state.modules.subModules" :key="household.id"
            :title="household.name" :icon="household.icon"
            :to="household.id == 'system' ? '/admin' : '/h/' + household.id">
        <v-flex class="d-flex justify-start flex-wrap">
          <div v-for="(module) in household.subModules" :key="module.id">
            <v-btn
              exact
              :to="'/h/' + household.id + '/' + module.url + '/' + module.id"
              height="80" width="80" class="mx-2 my-4" :text="module.color == null" :color="module.color">
              <v-flex>
                <div class="d-flex justify-center"><v-icon x-large>{{module.icon}}</v-icon></div>
                <div class="caption text-wrap d-flex justify-center align-center" style="height: 3em; max-width: 80px; text-align: center">{{module.name}}</div>
              </v-flex>
            </v-btn>
          </div>
        </v-flex>
      </Card>
    </div>

  </div>
</template>

<script>
import Card from '@/components/Card'
import ActionItems from '@/hoamodules/ActionItems.vue'
export default {
  name: 'Dashboard',
  components: { ActionItems, Card },
  data: () => ({
    showActionItems: { },
  }),
  computed: {
    anyShownActionItems() {
      let anyShownActionItems = false;
      Object.values(this.showActionItems).forEach(module => {
        if (module)
          anyShownActionItems = true;
      });
      return anyShownActionItems;
    }
  },
  mounted() {
    this.$emit('breadcrumbs', []);
    this.$emit('menuItems', []);
  }
}
</script>

<style scoped>

</style>
