<template>
  <div>
    <Card title="Households" icon="mdi-home-group">
      <EditableTable
        :table-subscription="(handler) => this.$api.household.subscribeAll(handler)"
        :fields="[
          {fieldName:'id', displayName: 'Id', type: String},
          {fieldName:'name', displayName: 'Name', editable: true, type: String, rules:[]},
          {fieldName:'enabled', displayName: 'Enabled', editable: true, type: Boolean, rules:[]},
          ]"
        :save-method="(data) => this.$api.household.saveHouseholdAdmin(data)"
      />
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import EditableTable from '@/components/EditableTable'
export default {
  name: 'Households',
  components: { EditableTable, Card },
}
</script>

<style scoped>

</style>
