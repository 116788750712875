<template>
  <div>
    <Card title="Artifact Repository" icon="mdi-file-cog" wide>
      <EditableTable
        :table-subscription="(handler) => this.$api.webrepo.subscribeAll(handler)"
        :fields="[
          {fieldName:'id', displayName: 'Id'},
          {fieldName:'name', displayName: 'Name'},
          {fieldName:'releaseChannel', displayName: 'Release Channel'},
          {fieldName:'commit', displayName: 'Commit'},
          {fieldName:'createdTimestamp', displayName: 'Created'},
          {fieldName:'hash', displayName: 'Hash'},
          {fieldName:'fileId', displayName: 'FileId'},
          ]"
      >
        <template v-slot:item.commit="{ item }">
          <div v-if="item.commit === 'dev' || item.commit.length == 14">{{item.commit}}</div>
          <a v-else :href="'https://gitlab.com/parry_household/hoa/-/commit/' + item.commit" target="_blank" rel="noopener noreferrer">{{item.commit}}</a>
        </template>
        <template v-slot:item.fileId="{ item }">
          <div class="shortColumn">
            <a :href="'/api/repo/' + item.releaseChannel + '/' + item.name" target="_blank" rel="noopener noreferrer">{{item.fileId}}</a>
          </div>
        </template>
      </EditableTable>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import EditableTable from '@/components/EditableTable'
export default {
  name: 'Repository',
  components: { EditableTable, Card },
}
</script>

<style scoped>

</style>
