<template>
  <div>
    <Card title="Add App to Household" icon="mdi-view-grid-plus">
      <v-btn
        @click="createListDialog = true"
        height="72" width="72" class="mx-2 my-4" color="#F9A825">
        <v-flex>
          <div class="d-flex justify-center"><v-icon x-large>mdi-clipboard-check-multiple</v-icon></div>
          <div class="caption text-truncate" style="max-width: 72px;">Lists</div>
        </v-flex>
      </v-btn>
      <v-btn
        @click="createNewtonDialog = true"
        text height="72" width="72" class="mx-2 my-4">
        <v-flex>
          <div class="d-flex justify-center"><v-icon x-large>mdi-router-network</v-icon></div>
          <div class="caption text-truncate" style="max-width: 72px;">Newton</div>
        </v-flex>
      </v-btn>
      <v-btn
        @click="createFinancialModuleDialog = true"
        height="72" width="72" class="mx-2 my-4" color="#084f09">
        <v-flex>
          <div class="d-flex justify-center"><v-icon x-large>mdi-currency-usd</v-icon></div>
          <div class="caption text-truncate" style="max-width: 72px;">Financial</div>
        </v-flex>
      </v-btn>
<!--      <v-btn-->
<!--        @click="createTimerDialog = true"-->
<!--        text height="72" width="72" class="mx-2 my-4">-->
<!--        <v-flex>-->
<!--          <div class="d-flex justify-center"><v-icon x-large>mdi-clock-outline</v-icon></div>-->
<!--          <div class="caption text-truncate" style="max-width: 72px;">Timer</div>-->
<!--        </v-flex>-->
<!--      </v-btn>-->
    </Card>
    <v-dialog v-model="createListDialog" max-width="1023">
      <Card title="Create a new set of lists" icon="mdi-clipboard-check-multiple" inDialog>
        <DynamicForm
          :key="createListDialog"
          :object="{name: ''}"
          :fields="[{fieldName:'name', displayName:'Name', placeholder:'Shopping Lists', type: String, rules:[rules.required]}]"
          :save-method="(data) => this.$api.list.createListHolder(household.id, data.name)"
          :cancel-callback="() => this.createListDialog = false"
          :success-callback="(o) => {this.createListDialog = false; $router.push('/h/' + household.id + '/list/' + o.id)}"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>
    <v-dialog v-model="createNewtonDialog" max-width="1023">
      <Card title="Connect a Newton Device" icon="mdi-clipboard-check-multiple" inDialog>
        <DynamicForm
          :key="createNewtonDialog"
          :object="{name: ''}"
          :fields="[{fieldName:'code', displayName:'Code', placeholder:'', type: String, rules:[rules.required, rules.sixDigits]}]"
          :save-method="(data) => this.$api.newton.connectNewtonToHousehold(household.id, data.code)"
          :cancel-callback="() => this.createNewtonDialog = false"
          :success-callback="(o) => {this.createNewtonDialog = false; $router.push('/h/' + household.id + '/newton/' + o.id)}"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>
    <v-dialog v-model="createFinancialModuleDialog" max-width="1023">
      <Card title="Create a Financial App" icon="mdi-currency-usd" inDialog>
        <DynamicForm
          :key="createFinancialModuleDialog"
          :object="{name: 'Financial'}"
          :fields="[
            {fieldName:'name', displayName:'Name', placeholder:'Financial', type: String, rules:[rules.required]},
            {fieldName:'createEnvelopes', displayName:'Create envelopes from template', type: Boolean},
            ]"
          :save-method="(data) => this.$api.financial.createFinancialModule(household.id, data.name, data.createEnvelopes)"
          :cancel-callback="() => this.createFinancialModuleDialog = false"
          :success-callback="(o) => {this.createFinancialModuleDialog = false; $router.push('/h/' + household.id + '/f/' + o.id)}"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>
    <v-dialog v-model="createTimerDialog" max-width="1023">
      <Card title="Connect a Timer" icon="mdi-clipboard-check-multiple" inDialog>
        <DynamicForm
          :key="createTimerDialog"
          :object="{name: ''}"
          :fields="[{fieldName:'code', displayName:'Code', placeholder:'', type: String, rules:[rules.required, rules.sixDigits]}]"
          :save-method="(data) => this.$api.newton.connectNewtonToHousehold(household.id, data.code)"
          :cancel-callback="() => this.createTimerDialog = false"
          :success-callback="(o) => {this.createTimerDialog = false; $router.push('/h/' + household.id + '/timer/' + o.id)}"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
  import Card from '@/components/Card'
  import DynamicForm from '@/components/DynamicForm'
  export default {
    name: 'HouseholdAddApp',
    props: ['household'],
    components: { DynamicForm, Card },

    data: () => ({
      createListDialog: false,
      createNewtonDialog: false,
      createTimerDialog: false,
      createFinancialModuleDialog: false,
      rules: {
        required: value => !!value || 'Required',
        sixDigits: value => (!!value && value.length === 6) || 'Code must be 6 characters',
      }
    }),
    mounted() {
      this.$emit('breadcrumbs', []);
      this.$emit('resetMenu');
    },
  }
</script>

<style scoped>

</style>
