var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',{attrs:{"title":"Users","icon":"mdi-home-group"}},[_c('EditableTable',{attrs:{"table-subscription":(handler) => this.$api.user.subscribeAll(handler),"fields":[
        {fieldName:'id', displayName: 'Id', type: String},
        {fieldName:'name', displayName: 'Name', editable: false, type: String, rules:[]},
        {fieldName:'verifiedEmailAddress', displayName: 'Email', editable: false, type: String, rules:[]},
        {fieldName:'username', displayName: 'Username', editable: false, type: String, rules:[]},

        {fieldName:'enabled', displayName: 'Enabled', editable: true, type: Boolean, rules:[]},
        ],"save-method":(data) => this.$api.user.saveUserAdmin(data)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }