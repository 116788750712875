<template>
  <div>
    <Card title="Transactions Sync" icon="mdi-sync" wide>
      <v-data-table
        style="border-collapse: separate;"
        v-if="pendingImports"
        :items="pendingImports"
        item-key="id"
        :expanded.sync="importRowExpanded"
        :headers="importHeaders"
        single-expand
        hide-default-footer
        disable-pagination
        disable-sort
        show-expand
        :item-class="getImportRowClass"
        no-data-text="No transactions found."
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            Debug file: {{item.fileId}} <br/>
            <div v-if="item.errorMessage">
              {{ item.errorMessage }}
            </div>
            <div v-else>
              <div>
                <v-select
                  :disabled="!!item.processedTimestamp || item.saving"
                  :readonly="!!item.processedTimestamp || item.saving"
                  style="margin-top: 12px"
                  v-model="item.overrideAccountId"
                  :items="accounts"
                  item-text="name"
                  item-value="id"
                  label="Account Override"
                  dense
                  outlined
                  v-on:change="item.saving = true; $api.financial_transaction_import.setTransactionImportAccountOverride(item.financialModuleId, item.id, item.overrideAccountId)"
                >
                </v-select>
              </div>
              <v-data-table
                :items="getTransactions(item)"
                :headers="getHeaders(item)"
                hide-default-footer
                disable-pagination
                disable-sort
                no-data-text="No transactions found."
              >
                <template v-slot:item.date="{ value }">
                  {{ value }}
                </template>
                <template v-slot:item.amount="{ value }">
                  {{ formatMoney(value) }}
                </template>
                <template v-slot:item.accountId="{ value }">
                  {{ accountNames[value] }}
                </template>
                <template v-slot:item.pending="{ item }">
                  <v-checkbox disabled v-model="item.pending"/>
                </template>
              </v-data-table>
            </div>
          </td>
        </template>

        <template v-slot:item.createdTimestamp="{ value }">
          {{ new Date(value).toLocaleString() }}
        </template>
        <template v-slot:item.source="{ item }">
          <div v-if="item.fileType === 'email'">
            Email: {{ JSON.parse(item.source).subject }}
          </div>
          <div v-else>
            {{ item.source.replaceAll("_","-") }}
          </div>
          <div v-if="item.errorMessage">{{ item.errorMessage }}</div>
        </template>
        <template v-slot:item.accountId="{ item }">
          <div v-if="item.overrideAccountId">
            {{ accountNames[item.overrideAccountId] }}
          </div>
          <div v-else v-for="group in item.transactionImportGroups" :key="group.accountId">
            {{ accountNames[group.accountId] }}
          </div>
        </template>
        <template v-slot:item.create="{ item }">
          <div v-for="group in item.transactionImportGroups" :key="group.accountId">
            <div>{{ (group.creditDebitTransactionsToCreate.length + group.investmentTransactionsToCreate.length) }}</div>
          </div>
        </template>
        <template v-slot:item.update="{ item }">
          <div v-for="group in item.transactionImportGroups" :key="group.accountId">
            <div>{{ (group.creditDebitTransactionsToUpdate.length + group.investmentTransactionsToUpdate.length) }}</div>
          </div>
        </template>
        <template v-slot:item.delete="{ item }">
          <div v-for="group in item.transactionImportGroups" :key="group.accountId">
            <div>{{ (group.creditDebitTransactionsToDelete.length + group.investmentTransactionsToDelete.length) }}</div>
          </div>
        </template>
        <template v-slot:item.ignore="{ item }">
          <div v-for="group in item.transactionImportGroups" :key="group.accountId">
            <div>{{ (group.creditDebitTransactionsToIgnore.length + group.investmentTransactionsToIgnore.length) }}</div>
          </div>
        </template>
        <template v-slot:item.unmodifiedTransactions="{ item }">
          <div v-for="group in item.transactionImportGroups" :key="group.accountId">
            <div>{{ group.unmodifiedTransactions }}</div>
          </div>
        </template>
        <template v-slot:item.id="{ item }">
          <div v-if="!item.processedTimestamp">
            <v-btn x-small fab :elevation="0"
                   :disabled="item.saving"
                   @click="item.saving = true; $api.financial_transaction_import.deletePendingSourceTransaction(item.financialModuleId, item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn x-small fab :elevation="0"
                   :disabled="item.saving"
                   @click="item.saving = true; $api.financial_transaction_import.previewPendingSourceTransaction(item.financialModuleId, item.id)">
              <v-icon>mdi-clipboard-text-search</v-icon>
            </v-btn>
            <v-btn v-if="item.ready"
                   :disabled="item.saving"
                   color="primary" x-small fab :elevation="0"
                   @click="item.saving = true; $api.financial_transaction_import.processPendingSourceTransaction(item.financialModuleId, item.id)">
              <v-icon>mdi-clipboard-text-play</v-icon>
            </v-btn>
          </div>
          <div v-else>
            {{ new Date(item.processedTimestamp).toLocaleString() }}
            <v-btn
              x-small fab :elevation="0"
              :to="'/h/' + household.id + '/f/' + financialModule.id + '/transactions/s/' + item.id"
            >
              <v-icon>mdi-email-newsletter</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <br/>
      <!--      To import files, drag your exports on to any financial page or select it here:-->

      <v-card-actions>
        <v-file-input
          reverse
          v-on:change="$emit('uploadFile', $event)"
          id="fileButton"
          style="display: none;"
        >
        </v-file-input>
        <v-spacer/>
        <v-btn color="primary" fab :elevation="0" @click="showUploadFileDialog()">
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </v-card-actions>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import { TransactionImport } from '@/api'
export default {
  name: 'Investing',
  components: { Card },
  props: ['household', 'financialModule', 'accounts', 'envelopes'],
  data: () => ({
    pendingSourceSubscription: null,
    pendingImports: [],
    importRowExpanded: [],
    focusInputButton: false,
  }),
  computed: {
    accountNames() {
      const names = {};
      this.accounts.forEach(account => {
        names[account.id] = account.name;
      });
      names[undefined] = "NotFound";
      names[''] = "NotFound";
      return names;
    },
    importHeaders() {
      const headers =
        [
          { text: 'Created', value: 'createdTimestamp' },
          { text: 'Source', value: 'source' },
          { text: 'Account', value: 'accountId' }
        ];

      let totalCreate = 0;
      let totalUpdate = 0;
      let totalDelete = 0;
      let totalIgnore = 0;
      let totalUnmodified = 0;

      this.pendingImports.forEach(transactionImport => {
        totalCreate += transactionImport.totalCreate;
        totalUpdate += transactionImport.totalUpdate;
        totalDelete += transactionImport.totalDelete;
        totalIgnore += transactionImport.totalIgnore;
        totalUnmodified += transactionImport.totalUnmodified;
      });

      if (totalCreate > 0)
        headers.push({ text: 'Create', value: 'create' });
      if (totalUpdate > 0)
        headers.push({ text: 'Update', value: 'update' });
      if (totalDelete > 0)
        headers.push({ text: 'Delete', value: 'delete' });
      if (totalIgnore > 0)
        headers.push({ text: 'Ignore', value: 'ignore' });
      if (totalUnmodified > 0)
        headers.push({ text: 'Unmodified', value: 'unmodifiedTransactions' });

      headers.push({ text: '', value: 'data-table-expand' });
      headers.push({ text: 'Processed', value: 'id' });
      // headers.push({ text: 'Processed', value: 'id', width: '160px' });

      return headers;
    }
  },
  methods: {
    getImportRowClass(pendingImport) {
      if (pendingImport.ready)
        return "transactionImportReady";
      else if (pendingImport.errorMessage)
        return "transactionImportError";

      return "";
    },
    getHeaders(transactionImport) {
      const headers =
        [
          { text: 'Matched Account', value: 'accountId' },
          { text: 'Type', value: 'type' },
          { text: 'Date', value: 'date' },
          { text: 'Description', value: 'description' },
          { text: 'Amount', value: 'amount' },
          { text: 'Pending', value: 'pending' },
        ];

      let showAccountFromFile = false;
      this.getTransactions(transactionImport).forEach(transaction => {
        if (transaction.accountInfo)
          showAccountFromFile = true;
      })

      if (showAccountFromFile)
        headers.splice(1, 0, { text: 'Account from file', value: 'accountInfo' });

      return headers;
    },
    getTransactions(transactionImport) {
      const transactions = [];
      if (transactionImport.transactionImportGroups) {
        transactionImport.transactionImportGroups.forEach(group => {
          group.creditDebitTransactionsToCreate.forEach(transaction => {
            transaction.type = 'Create';
            transaction.accountId = group.accountId;
            transactions.push(transaction);
          });
          group.creditDebitTransactionsToUpdate.forEach(transaction => {
            transaction.type = 'Update';
            transaction.accountId = group.accountId;
            transactions.push(transaction);
          });
          group.creditDebitTransactionsToDelete.forEach(transaction => {
            transaction.type = 'Delete';
            transaction.accountId = group.accountId;
            transactions.push(transaction);
          });
          group.creditDebitTransactionsToIgnore.forEach(transaction => {
            transaction.type = 'Ignore';
            transaction.accountId = group.accountId;
            transactions.push(transaction);
          });
          group.investmentTransactionsToCreate.forEach(transaction => {
            transaction.type = 'Create';
            transaction.accountId = group.accountId;
            transactions.push(transaction);
          });
          group.investmentTransactionsToUpdate.forEach(transaction => {
            transaction.type = 'Update';
            transaction.accountId = group.accountId;
            transactions.push(transaction);
          });
          group.investmentTransactionsToDelete.forEach(transaction => {
            transaction.type = 'Delete';
            transaction.accountId = group.accountId;
            transactions.push(transaction);
          });
          group.investmentTransactionsToIgnore.forEach(transaction => {
            transaction.type = 'Ignore';
            transaction.accountId = group.accountId;
            transactions.push(transaction);
          });
        });
      }
      transactions.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      return transactions;
    },
    showUploadFileDialog() {
      document.getElementById('fileButton').click();
    }
  },
  mounted() {
    this.$emit('breadcrumbs', [
      {
        text: 'Sync',
        icon: 'mdi-sync',
        to: '/h/' + this.household.id + '/f/' + this.financialModule.id + '/sync'
      }
    ]);

    this.$api.financial_transaction_import.subscribeToRecentSourceTransactions(this.financialModule.id, newObject => {
      let totalCreate = 0;
      let totalUpdate = 0;
      let totalDelete = 0;
      let totalIgnore = 0;
      let totalUnmodified = 0;

      if (newObject.transactionImportGroups) {
        newObject.transactionImportGroups.forEach(group => {
          if (group.creditDebitTransactionsToCreate)
            totalCreate += group.creditDebitTransactionsToCreate.length;
          if (group.creditDebitTransactionsToUpdate)
            totalUpdate += group.creditDebitTransactionsToUpdate.length;
          if (group.creditDebitTransactionsToDelete)
            totalDelete += group.creditDebitTransactionsToDelete.length;
          if (group.creditDebitTransactionsToIgnore)
            totalIgnore += group.creditDebitTransactionsToIgnore.length;
          if (group.investmentTransactionsToCreate)
            totalCreate += group.investmentTransactionsToCreate.length;
          if (group.investmentTransactionsToUpdate)
            totalUpdate += group.investmentTransactionsToUpdate.length;
          if (group.investmentTransactionsToDelete)
            totalDelete += group.investmentTransactionsToDelete.length;
          if (group.investmentTransactionsToIgnore)
            totalIgnore += group.investmentTransactionsToIgnore.length;
          if (group.unmodifiedTransactions)
            totalUnmodified += group.unmodifiedTransactions;
        });
      }

      newObject.totalCreate = totalCreate;
      newObject.totalUpdate = totalUpdate;
      newObject.totalDelete = totalDelete;
      newObject.totalIgnore = totalIgnore;
      newObject.totalUnmodified = totalUnmodified;

      newObject.ready = !newObject.processedTimestamp && (newObject.totalCreate > 0 || newObject.totalDelete > 0 || newObject.totalUpdate) && !newObject.parseError;

      let index = null;
      for (let i = 0; i < this.pendingImports.length; i++)
        if (this.pendingImports[i].id === newObject.id)
          index = i;
      if (index !== null) {
        // this.envelopes[index] = newObject; // We must splice so vue will see the changes
        if (newObject.deletedTimestamp)
          this.pendingImports.splice(index, 1);
        else
          this.pendingImports.splice(index, 1, newObject);
      } else {
        this.pendingImports.splice(0, 0, newObject);
        this.pendingImports.sort((a, b) => new Date(b.createdTimestamp).getTime() - new Date(a.createdTimestamp).getTime());
      }
    })
  },
  destroyed () {
    if (this.pendingSourceSubscription)
      this.$api.unsubscribe(this.pendingSourceSubscription);
  }
}
</script>

<style>
.transactionImportReady {
  background-color: #002700;
}
.transactionImportError {
  background-color: #440000;
}
</style>
