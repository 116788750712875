<template>
  <div>
    <v-form v-if="!userCreated" @submit="next">
      <v-card-text>
        <v-text-field autofocus v-model="name" label="Name" prepend-icon="mdi-account-circle" :readonly="isRegistering" :rules="[rules.required]" validate-on-blur/>
        <v-text-field v-model="email" label="Email" prepend-icon="mdi-email" :readonly="isVerifyingEmail || isEmailVerificationSent" :rules="[rules.required, rules.email]" validate-on-blur/>
        <v-text-field autofocus v-if="isEmailVerificationSent"  v-model="token" label="Security Code" prepend-icon="mdi-email-lock" :readonly="isRegistering" :rules="[rules.required, rules.token]" validate-on-blur/>
        <v-text-field v-model="username" v-if="isEmailVerificationSent" prepend-icon="mdi-account" label="Username" :readonly="isRegistering" :rules="[rules.required, rules.username]"/>
        <v-text-field v-model="password" v-if="isEmailVerificationSent" prepend-icon="mdi-lock" label="Password" :readonly="isRegistering" :rules="[rules.required, rules.password]"
                      :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"/>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="secondary" to="/">Sign in</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="isEmailVerificationSent" color="primary" type="submit" @click="next" :disabled="!isRegisterFormValid" :loading="isRegistering">Register</v-btn>
        <v-btn v-else color="primary" type="submit" @click="next" :disabled="!isVerifyFormValid" :loading="isVerifyingEmail">Verify email</v-btn>
      </v-card-actions>
    </v-form>
    <div v-else>
      <blockquote>
        <h2 class="text-center">Welcome {{this.name}}!</h2>
        <h3 class="text-center">Please sign in.</h3>
        <br/>
      </blockquote>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="secondary" to="/">Sign in</v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',

  data: () => ({
    isVerifyingEmail: false,
    isEmailVerificationSent: false,
    isRegistering: false,
    userCreated: false,
    name: "",
    email: "",
    username: "",
    password: "",
    token: "",
    showPassword: false,
    errorMessage: "",

    rules: {
      required: value => !!value || 'Required',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid email'
      },
      password: value => {
        const pattern = /(?=.{8,})(?=.*[a-zA-Z])(?=.*[!#$%&?_\- "]).*$/
        if (value && value.length > 60)
          return "Password must be less than 60 characters";
        return pattern.test(value) || 'Password must be between 8 and 60 characters and include upper case letters, lower case letters, and one of the following: !#$%&? _-'
      },
      token: value => {
        const pattern = /^\d{6}$/
        return pattern.test(value) || 'Security code is 6 numbers'
      },
      username: value => {
        const pattern = /^\w{3,100}$/
        return pattern.test(value) || 'Username must be between 3 and 100 characters and only include _, letters, and numbers'
      }
    }
  }),

  computed: {
    isVerifyFormValid() {
      return this.rules.required(this.name) === true &&
        this.rules.required(this.email) === true && this.rules.email(this.email) === true;
    },
    isRegisterFormValid() {
      return this.rules.required(this.name) === true &&
        this.rules.required(this.email) === true && this.rules.email(this.email) === true &&
        this.rules.required(this.password) === true && this.rules.password(this.password) === true &&
        this.rules.required(this.token) === true && this.rules.token(this.token) === true;
    }
  },

  methods: {
    next: function (e) {
      e.preventDefault();

      if (!this.isVerifyFormValid)
        return;

      if (this.isVerifyingEmail || this.isRegistering)
        return;

      if (!this.isEmailVerificationSent) {
        this.isVerifyingEmail = true;

        this.$api.user.sendVerificationEmail(this.email)
          .then(() => {
            this.isEmailVerificationSent = true;
          })
          .finally(() => {
            this.isVerifyingEmail = false;
          });
      } else {
        this.isRegistering = true;

        this.$api.user.registerUser(this.name, this.email, this.username, this.password, this.token)
          .then(() => {
            this.userCreated = true;
          })
          .finally(() => {
            this.isRegistering = false;
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
