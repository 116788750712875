<template>
  <Card title="System Dashboard" icon="mdi-view-dashboard">
    Probably copy this card from the main dashboard?
  </Card>
</template>

<script>
  import Card from '@/components/Card'
  export default {
    name: 'AdminDashboard',
    components: { Card }
  }
</script>

<style scoped>

</style>
