<template>
  <div>
    <div v-if="household" :key="household.id">
      <router-view
        :household="household"
        v-on:breadcrumbs="setBreadcrumbs($event)"
        v-on:menuItems="$emit('menuItems', $event)"
        v-on:resetMenu="resetMenu(household)"
        v-on:showError="$emit('showError', $event)"
      />
    </div>
    <div v-else-if="resolved">
      <Card title="Not Found" icon="mdi-alert">
        <div>Sorry, we couldn't find that household.</div>
      </Card>
    </div>
  </div>

</template>

<script>
  import Card from '@/components/Card'
  export default {
    name: 'Household',
    components: { Card },
    props: ['householdId'],

    data: () => ({
      household: null,
      subscription: null,
      resolved: false,
      childBreadcrumbs: []
    }),

    methods: {
      setBreadcrumbs(childBreadcrumbs) {
        this.childBreadcrumbs = childBreadcrumbs;
        this.updateBreadcrumbs();
      },
      updateBreadcrumbs() {
        const breadcrumbs = [{
          text: this.household.name,
          icon: 'mdi-home',
          to: '/h/' + this.household.id
        }];

        if (this.childBreadcrumbs && this.childBreadcrumbs.forEach)
          this.childBreadcrumbs.forEach((crumb) => {
            // crumb.to =
            breadcrumbs.push(crumb)
          });

        this.$emit('breadcrumbs', breadcrumbs);
      },
      resetMenu(household) {
        const menuItems = [
          {
            title: household.name,
            icon: 'mdi-home',
            to: '/h/' + household.id
          }];

        this.$store.state.modules.subModules.forEach(module => {
          if (module.id === household.id) {
            module.subModules.forEach(subModule => {
              menuItems.push(
                {
                  title: subModule.name,
                  icon: subModule.icon,
                  to: '/h/' + household.id + '/' + subModule.url + '/' + subModule.id,
                  color: subModule.color,
                }
              )
            });
          }
        });

        menuItems.push({
          title: 'Add App',
          icon: 'mdi-plus',
          to: '/h/' + household.id + '/add'
        });
        menuItems.push({
          title: 'Settings',
          icon: 'mdi-cog',
          to: '/h/' + household.id + '/settings'
        })
        this.$emit('menuItems', menuItems);
      },
      resubscribe(householdId) {
        if (this.subscription)
          this.$api.unsubscribe(this.subscription);

        this.resolved = false;
        this.subscription = this.$api.household.subscribe(householdId, household => {
          this.resetMenu(household);
          this.household = household;
          this.updateBreadcrumbs();
        }).unsubscribed.then(() => {
          this.household = null;
          this.resolved = true;
        });
      }
    },

    watch: {
      householdId(householdId) {
        this.resubscribe(householdId);
      }
    },

    mounted() {
      this.resubscribe(this.householdId);
    },

    destroyed() {
      if (this.subscription)
        this.$api.unsubscribe(this.subscription);
    }
  }
</script>

<style scoped>

</style>
